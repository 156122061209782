import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Logo from './images/pinkrobot-dark.png';
import Dashboard from './pages/Dashboard';
import Project from './pages/Project';
import ProjectDetails from './pages/ProjectDetails';
import Login from './pages/Login';
import Calendar from './pages/Calendar';
import NewAccount from './pages/NewAccount';
import Board from './pages/Board';
import Clients from './pages/Clients';
import WelcomePage from './pages/WelcomePage';
import ClientDetails from './pages/ClientDetails';
import AddClient from './pages/AddClient';
import NewProject from './pages/NewProject';
import RegistrationForm from './pages/RegistrationForm';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import UserProfile from './pages/UserProfile';


function App() {
    const capitalize = (str) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }
    const [pageTitle, setPageTitle] = useState("Home");
    
    const location = useLocation();
    const isLoginPage = location.pathname === "/user-login";
    const isNewAccountPage = location.pathname === "/new-account";
    const isActivatePage = location.pathname.startsWith("/activate/");
    const isRegistrationPage = location.pathname === "/sign-up";
    const shouldHideSidebar = isLoginPage || isNewAccountPage || isActivatePage || isRegistrationPage;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const dropdownRefs = useRef({});

    const menuItems = [
        {
            key: 'dashboard',
            label: 'Dashboard',
            icon: 'bx bxs-home',
            links: [
                { name: 'Dashboard', url: '/' },
                { name: 'User Profile', url: '/user-profile' },
                // { name: 'User Login', url: '/user-login' },
                // { name: 'New Account', url: '/new-account' }
            ]
        },
        {
            key: 'project',
            label: 'Project',
            icon: 'bx bxs-bolt',
            links: [
                {name:'New Project', url:'/new-project'},
                { name: 'Projects', url: '/project' },
                
            ]
        },
        {
            key: 'client',
            label: 'Client',
            icon: 'bx bxs-user',
            links: [
                { name: 'Clients', url: '/clients' }
            ]

        },
        {
            key: 'calendar',
            label: 'Calendar',
            icon: 'bx bx-calendar',
            url: '/calendar'
        }
        // Add other menu items here...
    ];

    // Function to detect if the viewport width is less than 768px (example for mobile view)
    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 1200);
    };

    const handleResize = () => {
        checkIsMobile();
    };

    // Effect to add resize event listener and check initial state
    useEffect(() => {
        checkIsMobile(); // Check initial state
        window.addEventListener('resize', handleResize);

        // Clean up function to remove event listener
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures this effect runs only once

    // Function to toggle sidebar visibility and add class for mobile
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);

        if (isMobile) {
        // Add or remove class from div with classname 'sidebar'
        const sidebarDiv = document.querySelector('.sidebar-expand');
        sidebarDiv.classList.remove('active'); // Toggle 'mobile-open' class
        document.body.classList.remove('overlay-sidebar');
        } else {
            const sidebarDiv = document.querySelector('.sidebar-expand');
            sidebarDiv.classList.add('active');
        }
    };

    const [dropdownOpen, setDropdownOpen] = useState({});

    const toggleDropdown = (key) => {
        setDropdownOpen(prevState => ({
            ...prevState, // Copy the existing state
            [key]: !prevState[key] // Toggle the current dropdown state
        }));
    };

    return (
        <div className={`sidebar-expand`}>

{!shouldHideSidebar ? (
                <>
                    <div className="sidebar">
                        <div className="sidebar-logo">
                            <a href="index.html">
                                <img src={Logo} className="pr-logo" alt="logo" />

                            </a>
                            <div className="sidebar-close" id="sidebar-close" onClick={toggleSidebar}>
                                <i className='bx bx-left-arrow-alt'></i>
                            </div>
                        </div>
                        <div className="simlebar-sc" data-simplebar>

                            <ul className="sidebar-menu tf">
                                {menuItems.map(item => (
                                    item.links ?
                                        <li key={item.key} className="sidebar-submenu">
                                            <a href="#" className={`sidebar-menu-dropdown ${activeMenu === item.key ? 'active' : ''}`} onClick={(e) => {
                                                e.preventDefault();
                                                toggleDropdown(item.key);
                                            }}>
                                                <i className={item.icon}></i>
                                                <span>{item.label}</span>
                                                <div className={`dropdown-icon ${dropdownOpen[item.key] ? 'active' : ''}`}>
                                                    <i className='bx bx-chevron-down'></i>
                                                </div>

                                            </a>
                                            <ul className={`sidebar-menu sidebar-menu-dropdown-content ${dropdownOpen[item.key] ? 'active' : ''}`}>
                                                {item.links.map((link, linkIdx) => (
                                                    <li key={linkIdx}>
                                                        <Link to={link.url}>
                                                            {link.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        :
                                        <li key={item.key}>
                                            <Link to={item.url}><i className={item.icon}></i><span>{item.label}</span></Link>
                                        </li>
                                ))}
                            </ul>
                        </div>

                    </div>
                   
                </>
                
                ) : null}
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path="/user-profile" element={<PrivateRoute><UserProfile/></PrivateRoute>}/>
                    <Route path="/project" element={<PrivateRoute><Project /></PrivateRoute>} />
                    <Route path="/project/:id" element={<PrivateRoute><ProjectDetails /></PrivateRoute>} />
                    <Route path="/new-project" element={<PrivateRoute><NewProject/></PrivateRoute>}/>
                    <Route path="/user-login" element={<Login />} />
                    <Route path="/sign-up" element={<PrivateRoute><RegistrationForm/></PrivateRoute>}/>
                    <Route path="/activate/:uid/:token" element={<WelcomePage/>} />
                    <Route path="/calendar" element={<PrivateRoute><Calendar /></PrivateRoute>} />
                    <Route path="/new-account" element={<NewAccount />} />
                    <Route path="/board/:id" element={<PrivateRoute><Board /></PrivateRoute>} />
                    <Route path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
                    <Route path="/client-details/:client_id" element={<PrivateRoute><ClientDetails /></PrivateRoute>} />
                    <Route path="/add-client" element={<PrivateRoute><AddClient /></PrivateRoute>} />
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
