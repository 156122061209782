import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/grid.css';
import './styles/style.css';
import './styles/responsive.css';
import './App.scss';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
        <App />
    </BrowserRouter>
);
