const API_BASE = process.env.REACT_APP_API_BASE_URL

const clientsList = async token => {
    const url = `${API_BASE}/accounts/clients/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const clientDetailsById = async (token, id) => {
    console.log(id)
    const url =`${API_BASE}/users/${id}/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const clientDetails = async (token, id) => {
    const url =`${API_BASE}/accounts/clients/${id}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,

            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const getClientWorkspace = async (token, id) => {
    const url =`${API_BASE}/workspace/projects/${id}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,

            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const DataClients = {
    clientsList,
    clientDetailsById,
    clientDetails,
    getClientWorkspace
}

export default DataClients;