import React from 'react';
import Header from '../components/Header';
import CreateProject from '../components/CreateProject';

function NewProject() {
    return (
        <>
            <Header />
            <div className="main">
                <div className="main-content project">
                    <CreateProject isPage={true} />
                </div>
            </div>
        </>
    )
}

export default NewProject;