import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import DataClients from '../api/clients-api';
import _ from 'lodash';

function ClientDetails() {
    let params = useParams();
    const { authTokens } = useAuth();
    const [client, setClientDetails] = useState({});
    const [clientUser, setClientUser] = useState({});
    const [clientWorkspace, setClientWorkspaceName] = useState({});

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const res = await DataClients.clientDetails(authTokens, params.client_id);
                console.log(res);
                setClientDetails(res);

                if (res.user) {
                    try {
                        const resUser = await DataClients.clientDetailsById(authTokens, res.user);
                        setClientUser(resUser);
                        const workspace = await DataClients.getClientWorkspace(authTokens, res.user);
                        setClientWorkspaceName(workspace.name);
                    } catch (err) {
                        console.log(err);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };

        if (authTokens) {
            fetchClientData();
        }
    }, [authTokens, params.client_id]);

    return (
        <div>
            <Header />
            <div className="main">
                <div className="main-content client-details">
                    <div className="box overflow-hidden mb-30 text-center">
                        <div className="box client">
                            <div className="img-box">
                                <img src="/userImgPlaceholder.png" alt="" />
                                <div className="pulse-css"></div>
                            </div>
                        </div>
                        <h5 className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span mt-0">{client.name}</h5>
                        <h6 className="pro-user-desc text-muted fs-14">{_.capitalize(clientUser.user_details?.role || 'N/A')}</h6>
                        <div className="box-footer pt-41">
                            <div className="btn-list text-center">
                                <a href="#" className="btn btn-light"><i className="bx bxs-envelope"></i> </a>
                                <a href="#" className="btn btn-light"> <i className="bx bxs-phone-call"></i> </a>
                                <a href="#" className="btn btn-light"><i className="bx bxs-error-circle"></i></a>
                                <a href="#" className="btn btn-light"> <i className="bx bxs-message-alt-edit"></i> </a>
                            </div>
                        </div>
                    </div>

                    <div className="box left-dot mb-30">
                        <div className="box-header border-0 pd-0">
                            <div className="box-title fs-20 font-w600">Basic Info</div>
                        </div>
                        <div className="box-body pt-20 user-profile">
                            <div className="table-responsive">
                                <table className="table mb-0 mw-100 color-span">
                                    <tbody>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Client ID</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>#{client.id}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Email Address</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>{clientUser?.email || 'N/A'}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Contact no</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>{clientUser.user_details?.phone_no || 'N/A'}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Gender</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>[N/A in the backend]</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Country</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>[N/A in the backend]</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Address</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>{clientUser.user_details?.address || 'N/A'}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Status</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span className="badge badge-success">Active</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="box left-dot">
                        <div className="box-header border-0 pd-0">
                            <div className="box-title fs-20 font-w600">Company Details</div>
                        </div>
                        <div className="box-body pt-20 user-profile">
                            <div className="table-responsive">
                                <table className="table mb-0 mw-100 color-span">
                                    <tbody>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Company Name</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>{_.capitalize(clientWorkspace || 'N/A')}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Country</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>[N/A in the backend]</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Address</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>[N/A in the backend]</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Contact No</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>[N/A in the backend]</span></td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-0"><span className="w-50">Website</span></td>
                                            <td>:</td>
                                            <td className="py-2 px-0"><span>[N/A in the backend]</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientDetails;
