import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
// import { useAuth } from '../context/AuthContext';
import AuthContext from '../context/AuthContext';
const PrivateRoute = ({children, ...rest}) => {
    // let { user } = useAuth();
    let { user } = useContext(AuthContext)
    return !user ? <Navigate to='/user-login'/> : children;
}

export default PrivateRoute;