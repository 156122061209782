import React, { useState, useEffect } from 'react';
import DataProjectServices from '../api/projects-api';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { Modal, Button, Dropdown } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Header from '../components/Header';
import CreateProject from '../components/CreateProject';
import Avatar from '../images/avt-1.png';

function Project() {
    const [projects, setProjects] = useState([]);
    const [showCreateProjectPopup, setShowCreateProjectPopup] = useState(false);
    const [events, setEvents] = useState([]);
    const [currentView, setCurrentView] = useState('list');
    const [calendarKey, setCalendarKey] = useState(0);
    // const [ client, setClient] = useState({});
    const { authTokens, user } = useAuth();


    const fetchData = async () => {
        try {     
            const res = await DataProjectServices.projectList(authTokens);
            const projectListById = res.results.filter(project => project.client.user === user.id );
            if(projectListById) {
                setProjects(projectListById);
                let eventList = [];
                projectListById.forEach(project => {
                    eventList.push({
                        title: project.name,
                        start: project.project_start,
                        end: project.end
                    });
                });
                setEvents(eventList);
            }
            
        } catch (err) {
            console.log(err);
        }
    };
  
    useEffect(() => {
        setCalendarKey((prevKey) => prevKey + 1);
        if (authTokens && user) {
            fetchData();
        }
    }, [authTokens, user, currentView]); 

    const handleUpdateProjects = () => {
        fetchData();
    };

    // useEffect(() => {
    //     const fetchClientData = async () => {
    //       try {
    //         const response = await DataProjectServices.getClientById(authTokens, user.id);
    //         if (response && response.results) {
    //           const client = response.results[0]; 
    //           console.log(client);
    //           setClient(client);
    //         } else {
    //           console.error("Failed to fetch client data");
    //         }
    //       } catch (error) {
    //         console.error("Error fetching client data:", error);
    //       }
    //     };
      
    //     if (authTokens && user.id) {
    //       fetchClientData();
    //     }
    //   }, [authTokens, user.id]); 
    
    const handleCreateProject = (event) => {
        setShowCreateProjectPopup(true);
    }

    const closeCreateProjectPopup = (event) => {
        setShowCreateProjectPopup(false);
    }

    const handleChangeView = (newView) => {
        setCurrentView(newView);
    };

    return (

        <div>
            <Header />
            <div className='main'>
                <div className="main-content project">
                    <div className="row">
                        <div className="col-9 col-xl-7 col-md-8 col-sm-12">
                            <div className="box card-box">
                                <div className="icon-box bg-color-6 d-block">
                                    <div className="content text-center color-6">
                                        <h5 className="title-box fs-17 font-w500">Total Project</h5>
                                        <div className="themesflat-counter fs-18 font-wb">
                                            <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">1225 +</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box bg-color-7 d-block">

                                    <div className="content text-center color-7">
                                        <h5 className="title-box fs-17 font-w500">Pending Project</h5>
                                        <div className="themesflat-counter fs-18 font-wb">
                                            <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">75 +</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box bg-color-8 d-block">

                                    <div className="content text-center color-8">
                                        <h5 className="title-box fs-17 font-w500">On Going project</h5>
                                        <div className="themesflat-counter fs-18 font-wb">
                                            <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">1225 +</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-box bg-color-9 d-block">

                                    <div className="content text-center color-9">
                                        <h5 className="title-box fs-17 font-w500">Complete Project</h5>
                                        <div className="themesflat-counter fs-18 font-wb">
                                            <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">2536 +</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 col-xl-5 col-md-4 col-sm-12">
                            <div className="box h-100 d-flex align-items-center">
                                <button className="create d-flex bg-primary justify-content-center" onClick={handleCreateProject}>
                                    <div className="icon color-white pt-4 pr-8">
                                        <i className='bx bx-plus-circle'></i>
                                    </div>
                                    <div className="content">
                                        <h5 className="color-white">Create New Project</h5>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box ">
                                <div className="box-header  pt-0">
                                    <div className="me-auto">
                                        <h4 className="card-title mb-0 fs-22">Projects</h4>

                                    </div>
                                    <div className="card-options pr-3">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-light" className="ripple fs-14">
                                                See All <i className="feather feather-chevron-down"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleChangeView('list')}>List</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleChangeView('dayGridMonth')}>Monthly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleChangeView('dayGridYear')}>Yearly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleChangeView('dayGridWeek')}>Weekly</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="box-body pb-0 table-responsive activity mt-18">
                                    {currentView === 'list' && 
                                        <table className="table table-vcenter text-nowrap table-bordered dataTable no-footer mw-100" id="task-profile" role="grid">
                                            <thead>
                                                <tr className="top">
                                                    <th className="border-bottom-0 sorting fs-14 font-w500" tabIndex="0" aria-controls="task-profile" rowSpan="1" colSpan="1" style={{ width: '50%' }}>Project</th>
                                                    <th className="border-bottom-0 sorting fs-14 font-w500" tabIndex="0" aria-controls="task-profile" rowSpan="1" colSpan="1" style={{ width: '50%' }}>Team</th>
                                                    <th className="border-bottom-0 sorting fs-14 font-w500" tabIndex="0" aria-controls="task-profile" rowSpan="1" colSpan="1" style={{ width: '50%' }}>Start</th>
                                                    <th className="border-bottom-0 sorting fs-14 font-w500" tabIndex="0" aria-controls="task-profile" rowSpan="1" colSpan="1" style={{ width: '50%' }}>Deadline</th>
                                                    <th className="border-bottom-0 sorting fs-14 font-w500" tabIndex="0" aria-controls="task-profile" rowSpan="1" colSpan="1" style={{ width: '50%' }}>Progress</th>
                                                    <th className="border-bottom-0 sorting fs-14 font-w500" tabIndex="0" aria-controls="task-profile" rowSpan="1" colSpan="1" style={{ width: '50%' }}>Work Status</th>
                                                    <th className="border-bottom-0 sorting_disabled fs-14 font-w500" rowSpan="1" colSpan="1">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {projects.map((project, index) => (
                                                    <tr key={project.id} className={index % 2 === 0 ? "even" : "odd"}>
                                                        <td>
                                                            <Link to={`/project/${project.id}`} className="d-flex">
                                                                <span>{project.name}</span>
                                                            </Link>
                                                        </td>
                                                        <td> 
                                                            <ul className="user-list mb-0">
                                                            
                                                                <li><img src={Avatar} alt="user" /></li>
                                                            </ul>
                                                        </td>
                                                        <td>{project.project_start || 'N/A'}</td>
                                                        <td>{project.project_end || 'N/A'}</td>
                                                        <td>
                                                            <div className="progress progress-sm">
                                                                <div className={`progress-bar bg-primary w-${project.progress}`}></div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className={`badge ${project.status === 'To Do' ? 'badge-warning' : 'badge-success'}`}>{project.status}</span>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <div className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className='bx bx-dots-horizontal-rounded'></i>
                                                                </div>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="bx bx-trash"></i> Delete</a>
                                                                    <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    }
                                    {currentView !== 'list' && 
                                        <FullCalendar
                                            key={calendarKey}
                                            plugins={[ dayGridPlugin ]}
                                            initialView={currentView}
                                            events={events}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                     {/* <div className="modal custom-modal fade" id="delete_project" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="form-header">
                                        <h3>Delete Card</h3>
                                        <p>Are you sure want to delete?</p>
                                    </div>
                                    <div className="modal-btn delete-action">
                                        <div className="row">
                                            <div className="col-6 mb-0">
                                                <a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
                                            </div>
                                            <div className="col-6 mb-0">
                                                <a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {showCreateProjectPopup &&
                        <Modal 
                            show={showCreateProjectPopup}
                            onHide={closeCreateProjectPopup}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="custom-modal">
                            <Modal.Header>
                                <Modal.Title>Create Project</Modal.Title>
                                <Button className="close" onClick={closeCreateProjectPopup}><span aria-hidden="true">&times;</span></Button>
                            </Modal.Header>
                            <Modal.Body>
                                <CreateProject isPopup={true} setShowCreateProjectPopup={setShowCreateProjectPopup} handleAdditionalAction={handleUpdateProjects} />
                            </Modal.Body>
                        </Modal>
                    }
                </div>
            </div>
        </div>



    );
}

export default Project;