const API_BASE = process.env.REACT_APP_API_BASE_URL

const createUser = async (formData) => {
    const url = API_BASE + '/auth/users/';
    const res = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    })
    return res.json()
}

const checkEmailExist = async (email) => {
    const url = `${API_BASE}/accounts/check_email/?email=${encodeURIComponent(email)}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    return data;
};

const activateUser = async (uid, token) => {
    const url = `${API_BASE}/auth/users/activation/`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, token }),
    })
    return res.ok
}

const createProject = async (formData, token) => {
    console.log(formData)
    const url = `${API_BASE}/workspace/projects/`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    })
    return res.json();
}

const attachFileProject = async (formData) => {
    console.log(formData)
    const url = `${API_BASE}/workspace/project_attachments/`;
    const res = await fetch(url, {
        method: "POST",
        body: formData, // FormData object, no need to stringify
        // Remove "Content-Type": "application/json" header, let the browser set it
    });
    return res.ok;
};

const getWorkspaces = async (token) => {
    console.log(token)
    const url = API_BASE + '/workspace/workspaces/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const getCollaborators = async (token) => {
    const url = API_BASE + '/accounts/collaborators/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const getCollaboratorById = async (token, id) => {
    const url = `${API_BASE}/accounts/collaborators/${id}/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const getClients = async (token) => {
    const url = API_BASE + '/accounts/clients/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const createClients = async (formData, token) => {
    const url = `${API_BASE}/accounts/clients/`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    });
    console.log(res)
    if (!res.ok) {          
        throw new Error(`Error: ${res.status}`);
    }
    return await res.json();
}

const registerUserInfo = async (token, id, formData) => {
    console.log(formData)
    const url = `${API_BASE}/users/${id}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            body: JSON.stringify(formData),
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            },
        });
        if (!res.ok) {          
            throw new Error(`Error: ${res.status}`);
        }
        return await res.json(); 
    } catch (error) {
        console.error("Failed to update user info:", error);
        throw error; 
    }
}

const createWorkspace = async (token, workspaceData) => {
    console.log(workspaceData)
    const url = `${API_BASE}/workspace/workspaces/`; // Using template literals for cleaner code
    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(workspaceData),
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });



        return res.json() // Parsing the JSON body of the response
    } catch (error) {

    }
}

const updateCard = async(cardId, token, data) => {
    const url = `${API_BASE}/workspace/cards/${cardId}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            headers: { 
                Authorization: `Token ${token}` ,
                "Content-Type": "application/json",
            },
            body: data,
        });
        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }
        return res.json();
    } catch (error) {
        console.error('Error updating card status:', error);
        throw error; 
    }
}

const updateCardStatus = async (cardId, newStatus, token) => {
    const url = `${API_BASE}/workspace/cards/${cardId}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            headers: { 
                Authorization: `Token ${token}` ,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ category: newStatus }),
        });
        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }
        return res.json();
    } catch (error) {
        console.error('Error updating card status:', error);
        throw error; 
    }
}

const updateCardDescription = async (cardId,token,newStatus) => {
    const url = `${API_BASE}/workspace/cards/${cardId}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            headers: { 
                Authorization: `Token ${token}` ,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ description: newStatus }),
        });
        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }
        return res.json();
    } catch (error) {
        console.error('Error updating card status:', error);
        throw error; 
    }
}

const updateCardTag = async (cardId,token,newStatus) => {
    console.log(newStatus)
    const url = `${API_BASE}/workspace/cards/${cardId}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            headers: { 
                Authorization: `Token ${token}` ,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ tags: newStatus }),
        });
        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }
        return res.json();
    } catch (error) {
        console.error('Error updating card status:', error);
        throw error; 
    }
}

const updateTodos = async (cardId,token,newStatus) => {
    console.log(newStatus)
    const url = `${API_BASE}/workspace/cards/${cardId}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            headers: { 
                Authorization: `Token ${token}` ,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ todo_list: newStatus }),
        });
        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }
        return res.json();
    } catch (error) {
        console.error('Error updating card status:', error);
        throw error; 
    }
}

const updateChecklists = async (cardId,token,newStatus) => {
    console.log(newStatus)
    const url = `${API_BASE}/workspace/cards/${cardId}/`;
    try {
        const res = await fetch(url, {
            method: "PATCH",
            headers: { 
                Authorization: `Token ${token}` ,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ checklist: newStatus }),
        });
        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }
        return res.json();
    } catch (error) {
        console.error('Error updating card status:', error);
        throw error; 
    }
}

const getCardAttachments = async (token)=>{
    const url = `${API_BASE}/workspace/card_attachments/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const cardAttachments = async (token, formData) => {
    const url = `${API_BASE}/workspace/card_attachments/`;
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });
  
      if (!res.ok) {
        throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
      }
  
      return await res.json();
    } catch (error) {
      console.error('Error uploading attachments:', error);
      throw error;
    }
  };

  const deleteCardAttachments = async (token, attachmentId) => {
    const url = `${API_BASE}/workspace/card_attachments/${attachmentId}`;
    try {
        const res = await fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: `Token ${token}`,
            },
           
        });

        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }

        return res.ok; 
    } catch (error) {
        console.error('Error deleting attachment:', error);
        throw error;
    }
};

const deleteCardId = async (token, cardId) => {
    const url = `${API_BASE}/workspace/cards/${cardId}`;
    try {
        const res = await fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: `Token ${token}`,
            },
            // body: formData, // Typically, DELETE requests don't include a body. Ensure this aligns with your API.
        });

        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }

        return res.ok; // Depending on your API, you might not get a JSON response for DELETE requests
    } catch (error) {
        console.error('Error deleting attachment:', error);
        throw error;
    }
};

const createBoards = async (token,boardData)=> {
    const url = `${API_BASE}/workspace/boards/`; 
    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(boardData),
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
        return res.json()
    }
    catch (error) {

    }
}

const createCards = async (token, cardData) => {
    const url = `${API_BASE}/workspace/cards/`; 
    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(cardData),
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });



        return res.json()
    }
    catch (error) {

    }
}

const getUsers = async (token) => {
    const url = API_BASE + '/users/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getAuthUsers = async (token) => {
    const url = API_BASE + '/auth/users/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getInvoice =  async token => {
    const url = `${API_BASE}/audit/invoices/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
};

const addCommentToCard = async (cardId, commentText, user, token) => {
    
    const url = `${API_BASE}/workspace/card_comments/`; 
    try {
      
        const commentsData = {
            message: commentText,
            emoji: '', 
            card: cardId, 
            user: user,
        };
         console.log(commentsData)
        const response = await fetch(url, {
            method: 'POST', 
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(commentsData),
        });

        if (!response.ok) {
            throw new Error(`Failed to add comment: ${response.statusText}`);
        }

        const newComment = response.json();
        return newComment;
    } catch (error) {
        console.error('Error adding comment:', error);
        throw error; // Or handle the error as needed
    }
};

const getCardById = async (cardId,token) => {
    const url = `${API_BASE}/workspace/cards/${cardId}/`; ;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const addReplyToComments = async (cardId,commentId,replyText, user,token) => { 
    const url = `${API_BASE}/workspace/card_comments/`; 
    try {
      
        const replyData = {
            message: replyText,
            reply_to: commentId,
            card: cardId,
            user: user,

        };
        console.log(replyData)
        const response = await fetch(url, {
            method: 'POST', 
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(replyData),
        });

        if (!response.ok) {
            throw new Error(`Failed to add comment: ${response.statusText}`);
        }

        const newReply = response.json();
        return newReply;
    } catch (error) {
        console.error('Error adding comment:', error);
        throw error; // Or handle the error as needed
    }
};

const deleteComment = async (commentId, token ) => {
    const url = `${API_BASE}/workspace/card_comments/${commentId}`;
    try {
        const res = await fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: `Token ${token}`,
            },
            // body: formData, // Typically, DELETE requests don't include a body. Ensure this aligns with your API.
        });

        if (!res.ok) {
            throw new Error(`Server responded with ${res.status}: ${res.statusText}`);
        }

        return res.ok; // Depending on your API, you might not get a JSON response for DELETE requests
    } catch (error) {
        console.error('Error deleting attachment:', error);
        throw error;
    }
};

const DataServices = {
    createUser,
    checkEmailExist,
    activateUser,
    createProject,
    attachFileProject,
    registerUserInfo,
    getWorkspaces,
    getCollaborators,
    getClients,
    getCardAttachments,
    createWorkspace,
    getUsers,
    getAuthUsers,
    getInvoice,
    createBoards,
    createCards,
    updateCard,
    updateCardStatus,
    updateCardDescription,
    updateCardTag,
    updateTodos,
    updateChecklists,
    cardAttachments,
    deleteCardAttachments,
    deleteCardId,
    addCommentToCard,
    getCardById,
    addReplyToComments,
    deleteComment,
    getCollaboratorById,
    createClients
}

export default DataServices;