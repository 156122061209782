import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, InputGroup, Modal } from 'react-bootstrap';
import DataServices from '../api/services';
import { useAuth } from '../context/AuthContext';
import logo from '../images/pinkrobot.png'
import { Link } from 'react-router-dom';


const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    user_details: {
      phone_no: '',
      address: '',
      role: '',
      subscription_type: '',

    }
  });
  const [workspaceData, setWorkspaceData] = useState({
    name: '',
    status: '',
    user: ''
  });

  const [showModal, setShowModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [users, setUsers] = useState([]);
  const { authTokens, user, setHasCompletedRegistration } = useAuth();

  useEffect(() => {
    if (user && user.id) { // Check if `user` and `user.id` are truthy
      setWorkspaceData(prevState => ({
        ...prevState,
        user: user.id,
        status: 'enabled' // Set the default status to 'enabled'
      }));
    }
  }, [user]);

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {

  //       const res = await DataServices.getUsers(authTokens, user);
  //       setUsers(res.results);
  //     } catch (error) {
  //       console.error('Failed to fetch users:', error);
  //     }

  //   };

  //   fetchUsers();
  // }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.'); // Split the name by '.' to check for nested properties

    // Special handling for the 'user' field to ensure correct data type
    if (name === 'user') {
        // Convert value to integer if the field is 'user', handling potential NaN with a fallback
        const intValue = parseInt(value, 10) || '';
        setWorkspaceData(prevState => ({
            ...prevState,
            [name]: intValue, // Use converted value or fallback
        }));
    } else if (name in workspaceData) {
        // Update workspaceData for other fields directly
        setWorkspaceData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    } else if (nameParts.length === 2) {
        // Handle nested property update in formData
        setFormData(prevState => ({
            ...prevState,
            [nameParts[0]]: {
                ...prevState[nameParts[0]],
                [nameParts[1]]: value,
            },
        }));
    } else {
        // Direct update in formData for non-nested properties
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }
};




   


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(user);


      const responseUser = await DataServices.registerUserInfo(authTokens, user.id, formData);
      console.log('User Info Response:', responseUser);


     const responseWorkspace = await DataServices.createWorkspace(authTokens, workspaceData);
      console.log('Workspace Info Response:', responseWorkspace);


      if (responseUser) {
        window.location.href = '/';
      } else {

        setErrorMessage('Failed to register all information.');
      }
    } catch (error) {
      console.error('Error updating user and workspace information:', error);
      setErrorMessage(error.message || 'An error occurred during registration.');
    }
  };




  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={10} xl={8}>
          <section className="login signup">
            <div className="col-md-12">
              <div className="box">
                <div className="mt-4">
                  <div className="box-header d-flex justify-content-between">
                    <a href="index.html">
                      <img src={logo} alt="Logo" />
                    </a>

                    <div className="action-reg">
                      <h4 className="fs-30">Registration</h4>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="box-body">
                    <div className="auth-content my-auto">
                      {success && <Alert variant="success">Registration successful!</Alert>}
                      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                      <Form className="mt-6 pt-2" onSubmit={handleSubmit}>
                        {/* Input fields */}
                        <Form.Group className="mb-3">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 mt-24">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 mt-24">
                          <Form.Label>Role</Form.Label>
                          <Form.Control as="select" name="user_details.role" value={formData.user_details.role} onChange={handleChange} required>
                            <option value="">Select a role</option>
                            <option value="collaborator">Collaborator</option>
                            <option value="staff">Staff</option>
                            <option value="owner">Owner</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3 mt-24">
                          <Form.Label>Workspace Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={workspaceData.name}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
{/* 
                        <Form.Group className="mb-3 mt-24">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            value={workspaceData.status}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Status</option>
                            <option value="enabled">Enabled</option>
                            <option value="disabled">Disabled</option>
                          </Form.Control>
                        </Form.Group> */}

                        <Button variant="primary" type="submit" className="w-100 mb-3 mt-24">Submit</Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationForm;
