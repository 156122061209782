const API_BASE = process.env.REACT_APP_API_BASE_URL

const projectList = async token => {
    const url = `${API_BASE}/workspace/projects/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const projectListById = async (token, id) => {
    console.log(id)
    const url =`${API_BASE}/workspace/projects/?client=${id}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}

const getClientById = async (token, id) => {
    console.log(id)
    const url =`${API_BASE}/accounts/clients/?user=${id}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        }
    })
    return res.json()
}


const getProjectById = async (token, id) => {
    const url = API_BASE + '/workspace/projects/' + id;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getBoardById = async (token,id) => {
    const url = API_BASE + '/workspace/boards/' + id;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getBoardsList = async token => {
    const url = `${API_BASE}/workspace/boards/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}


const DataProjectServices = {
    projectList,
    getProjectById,
    getClientById,
    getBoardById,
    getBoardsList,
    projectListById,
}

export default DataProjectServices;