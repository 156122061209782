import React, { useState, useRef, useEffect } from 'react';
import Avatar from '../images/avt-1.png';
import Header from '../components/Header';
import NotificationBox from '../components/NotificationBox';


function Dashboard() {


    return (
        <>
            <Header />
            <div className="main">


                <div className="main-content dashboard">
                    <NotificationBox />
                    <div className="row">
                        <div className="col-6 col-md-6 col-sm-12 mb-0">
                            <div className="row">
                                <div className="col-12">

                                    <div className="box f-height">
                                        <div className="box-header d-flex justify-content-between mb-wrap">
                                            <h3 className="mt-9 ml-5">Project Statistics</h3>
                                            <ul className="card-list mb-0">
                                                <li className="custom-label"><span></span>Complete</li>
                                                <li className="custom-label"><span></span>Doing</li>
                                            </ul>
                                        </div>
                                        <div className="box-body pt-20">
                                            <div id="customer-chart"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6 col-xl-12 col-sm-12">
                                    <div className="box">
                                        <div className="box-body d-flex pb-0">
                                            <div className="me-auto">
                                                <h5 className="box-title">Total Clients</h5>
                                                <div className="d-flex align-items-center">
                                                    <h4 className="mb-0 font-wb fs-30 mt-23">78</h4>
                                                    <div className="text-primary transaction-caret">
                                                        <i className="bx bxs-up-arrow"></i>
                                                        <p className="mb-0 text-primary fs-18 font-w400 mt-14 ml-7">+0.5%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" donut-chart-sale">
                                                <span className="donut-1" data-peity='{ "fill": ["#3C21F7", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>7/9</span>
                                                <small className="">76%</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-12 col-sm-12">
                                    <div className="box">
                                        <div className="box-body d-flex pb-0">
                                            <div className="me-auto">
                                                <h5 className="box-title mb-36">Total Task Done</h5>
                                                <div className="progress skill-progress mb-10" style={{ height: '10px' }}>
                                                    <div className="progress-bar bg-primary progress-animated" style={{ width: '78%', height: '10px' }} role="progressbar">
                                                        <span className="sr-only">78% Complete</span>
                                                    </div>
                                                </div>
                                                <p className="fs-16 mb-0 mt-2"><span className="text-primary">87 </span>left from target</p>
                                            </div>
                                            <h4 className="numb font-wb fs-30">34</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-12 col-sm-12">
                                    <div className="box">
                                        <div className="box-body d-flex pb-0">
                                            <div className="me-auto">
                                                <h4 className="numb fs-30 font-wb">565</h4>
                                                <h5 className="card-title fs-18 font-w400">Total Clients</h5>
                                                <p className="fs-14 mb-0 mt-11"><span className="text-primary">-3% </span>than last month</p>
                                            </div>
                                            <div id="total-revenue-chart"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-12 col-sm-12">
                                    <div className="box">
                                        <div className="box-body d-flex pb-0">
                                            <div className="me-auto">
                                                <h4 className="numb fs-30">565</h4>
                                                <h5 className="card-title fs-18 font-w400">New Projects</h5>
                                                <p className="fs-14 mb-0 mt-11"><span className="text-primary">+0.5% </span>than last month</p>
                                            </div>
                                            <div id="total-revenue-chart-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="box f-height">
                                        <div className="box-header d-flex justify-content-between">
                                            <h3 className="mt-9 ml-5">Project Statistics</h3>

                                        </div>
                                        <div className="box-body pt-20">
                                            <div id="chartBar3" className="bar-chart"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-sm-12 mb-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="box box-manage">
                                        <div className="box-body d-flex pd-7 pb-0">
                                            <div className="me-auto w-55">
                                                <h5 className="card-title text-white fs-30 font-w500 mt-4">Manage your project in one touch</h5>
                                                <p className="mb-0 text-o7 fs-18 font-w500 pb-11">Etiam facilisis ligula nec velit posuere egestas. Nunc dictum</p>
                                            </div>
                                            <div className="btn-now">
                                                <a className="h6 font-w500" href="#"><span>Try For Free Now</span></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6 col-xl-12 col-md-12 col-sm-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <div className="me-auto">
                                                <h6 className="card-title font-w400 mb-20">Current Balance</h6>
                                                <div className="count-number d-flex">
                                                    <span className="h4 font-w900">$</span>
                                                    <h4 className="count font-w900 pl-5">25,456.44</h4>
                                                </div>
                                                <p className="fs-18 mb-0 mt-6"><span className="text-primary pr-7">+3.2</span>than last week</p>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div id="chartBar2" className="bar-chart "></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-12 col-md-12 col-sm-12">
                                    <div className="box">
                                        <div className="box-body center">
                                            <div className="w-100"><span className="donut-2 w-100" data-peity='{ "fill": ["#9B8DFF", "#E4EAF8"]}'>228,134</span>
                                            </div>
                                            <h5 className="fs-20 mb-0 mt-20 font-w500 color-text mt-28">On Progress<span className="text-primary font-w600 pl-8">50% </span></h5>
                                            <h5 className="fs-22 mt-18 mb-10 font-wb">Workload Dashboard For CMS Website</h5>
                                            <p className="fs-18 mt-18">Praesent eu dolor eu orci vehicula euismod.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <h4 className="box-title mb-22">Daily Task</h4>
                                        </div>
                                        <div className="box-body">
                                            <div className="content-item mb-wrap">
                                                <div className="left">
                                                    <h5 className="font-w500">10:00</h5>
                                                </div>
                                                <div className="right bg-orange">
                                                    <div className="content-box w-100">
                                                        <h5 className="font-wb text-white fs-20">iOs Dev team meeting</h5>
                                                        <h6 className="font-w400 text-w07">10:00 - 11:00</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content-item mb-wrap mb-32">
                                                <div className="left">
                                                    <h5 className="font-w500">11:00</h5>
                                                </div>
                                                <div className="right d-flex pd-0">
                                                    <div className="content-box bg-yellow">
                                                        <h5 className="font-wb text-white fs-20">Design meeting</h5>
                                                        <h6 className="font-w400 text-w07">11:00 - 11:30</h6>
                                                    </div>
                                                    <div className="content-box bg-blue">
                                                        <h5 className="font-wb text-white fs-20">SEO meeting</h5>
                                                        <h6 className="font-w400 text-w07">11:30 12:00</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content-item mb-0 mb-wrap">
                                                <div className="left">
                                                    <h5 className="font-w500">12:00</h5>
                                                </div>
                                                <div className="right bg-light">
                                                    <div className="content-box w-100">
                                                        <h5 className="font-w500">Lunch Break</h5>
                                                        <h6 className="font-w400 mt-10">12:00 - 1:00</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="box">
                                <div className="box-header">
                                    <div className="me-auto">
                                        <h4 className="card-title mb-6">Project Statistics</h4>
                                        <p className="mb-0 fs-14 mt-9 ">Lorem ipsum dolor sit amet</p>
                                    </div>
                                </div>
                                <div className="box-body pt-20">
                                    <div className="themesflat-carousel-box data-effect has-bullets bullet-circle bullet24 clearfix" data-gap="30" data-column="4" data-column2="2" data-column3="1" data-auto="true">
                                        <div className="owl-carousel owl-theme">
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-green">G</h5>
                                                        <a href="project-details.html" className="h5 t-title">Gold App</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400 fs-16">Task Done:23/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }} >
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '52%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-yellow">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">Landing Page</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:30/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }}>
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '67%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-blue">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">App Landing UI</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:35/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }} >
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '78%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-orange">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">Blog Template UI</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:23/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }}>
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '52%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-green">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">Brand logo design</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:30/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }}>
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '68%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-yellow">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">Gold App</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:30/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }} >
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '67%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-blue">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">New Landing Design</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:35/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }}>
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '78%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box box-carousel">
                                                <div className="card-top">
                                                    <div className="sm-f-wrap d-flex">
                                                        <h5 className="icon-gold text-white bg-orange">G</h5>
                                                        <a className="h5 t-title" href="project-details.html">Skote Dashboard UI</a>
                                                    </div>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_project"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-center">
                                                    <h6 className="font-w400">Task Done:23/45</h6>
                                                    <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }}>
                                                        <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '52%' }} role="progressbar">
                                                        </div>
                                                    </div>
                                                    <div className="sm-f-wrap d-flex justify-content-between">
                                                        <ul className="user-list">
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                            <li><img src={Avatar} alt="user" /></li>
                                                        </ul>
                                                        <ul className="tf-icon-list">
                                                            <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                            <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-12">
                            <div className="box">
                                <div className="box-header">
                                    <div className="me-auto">
                                        <h4 className="card-title">Project Category</h4>
                                        <p>Lorem ipsum dolor sit amet</p>
                                    </div>
                                </div>
                                <div className="box-body pt-0">
                                    <div className="row">
                                        <div className="col-6 col-xl-12 w-sm-100 mb-0">
                                            <ul className="box-list mt-26 pr-67">
                                                <li><span className="bg-blue square"></span>Web Design<span>25%</span></li>
                                                <li><span className="bg-success square"></span>UX/UI Design<span>18%</span></li>
                                                <li><span className="bg-warning square"></span>Graphics Design<span>17%</span></li>
                                                <li><span className="bg-blue square"></span>Motion Design<span>12.50%</span></li>
                                                <li><span className="bg-success square"></span>Brand Identity<span>12.50%</span></li>
                                                <li><span className="bg-warning square"></span>Others<span>12.50%</span></li>
                                            </ul>
                                        </div>
                                        <div className="col-6 col-xl-12 w-sm-100 mb-0">
                                            <canvas id="doughnut-chart" width="240" height="240"></canvas>
                                            <div className="canvas-container">
                                                <canvas id="chartjs-4" className="chartjs" width="100" height="100"></canvas>
                                                <div className="chart-data">
                                                    <div data-percent="25" data-color="#3C21F7" data-label="Web Design"></div>
                                                    <div data-percent="18" data-color="#00BC8B" data-label="UX/UI Design"></div>
                                                    <div data-percent="17" data-color="#FFB800" data-label="Graphics Design"></div>
                                                    <div data-percent="12.5" data-color="#00ECCC" data-label="Motion Design"></div>
                                                    <div data-percent="12.5" data-color="#EF7F5A" data-label="Brand Identity"></div>
                                                    <div data-percent="12.5" data-color="#5D45FB" data-label="Others"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-12">
                            <div className="box">
                                <div className="box-header pt-0">
                                    <div className="me-auto">
                                        <h4 className="card-title mb-0">Message</h4>
                                    </div>
                                </div>
                                <div className="box-body pb-0 pt-39">
                                    <ul className="message mb-2">
                                        <li className="dlab-chat-user">
                                            <div className="d-flex bd-highlight">
                                                <div className="img_cont">
                                                    <img src="./images/avatar/message-01.png" className="rounded-circle user_img" alt="" />
                                                </div>
                                                <div className="user_info">
                                                    <a className="h6" href="message.html">Richard Coleman</a>
                                                    <p className="fs-14 mb-0">Hi Jackma! How are you?</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="dlab-chat-user">
                                            <div className="d-flex bd-highlight">
                                                <div className="img_cont border-pink">
                                                    <img src="./images/avatar/message-02.png" className="rounded-circle user_img" alt="" />
                                                </div>
                                                <div className="user_info">
                                                    <a className="h6" href="message.html">Jessica Elliot</a>
                                                    <p className="fs-14 mb-0">Do you need like the Color, typography and spacing? </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="dlab-chat-user">
                                            <div className="d-flex bd-highlight">
                                                <div className="img_cont border-green">
                                                    <img src="./images/avatar/message-03.png" className="rounded-circle user_img" alt="" />
                                                </div>
                                                <div className="user_info">
                                                    <a className="h6" href="message.html">Steve Ford</a>
                                                    <p className="fs-14 mb-0">Can I your budget?</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="dlab-chat-user">
                                            <div className="d-flex bd-highlight">
                                                <div className="img_cont border-blue">
                                                    <img src="./images/avatar/message-04.png" className="rounded-circle user_img" alt="" />
                                                </div>
                                                <div className="user_info">
                                                    <a className="h6" href="message.html">Mary Ann Lucas</a>
                                                    <p className="fs-14 mb-0">Looks Good. Please go forward!</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="add_project" className="modal custom-modal fade" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Create Project</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>Project Name</label>
                                                    <input className="form-control" value="" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>Client</label>
                                                    <select className="select">
                                                        <option>Client 1</option>
                                                        <option>Client 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>Start Date</label>
                                                    <div className="cal-icon">
                                                        <input className="form-control " type="date" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>End Date</label>
                                                    <div className="cal-icon">
                                                        <input className="form-control" type="date" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3 mb-0">
                                                <div className="form-group">
                                                    <label>Rate</label>
                                                    <input placeholder="$50" className="form-control" value="" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-0">
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <select className="select">
                                                        <option>Hourly</option>
                                                        <option selected>Fixed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>Priority</label>
                                                    <select className="select">
                                                        <option selected>High</option>
                                                        <option>Medium</option>
                                                        <option>Low</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea rows="4" className="form-control" placeholder="Enter your message here"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Upload Files</label>
                                            <input className="form-control" type="file" />
                                        </div>
                                        <div className="submit-section">
                                            <button className="btn btn-primary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal custom-modal fade" id="delete_project" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="form-header">
                                        <h3>Delete Project</h3>
                                        <p>Are you sure want to delete?</p>
                                    </div>
                                    <div className="modal-btn delete-action">
                                        <div className="row">
                                            <div className="col-6 mb-0">
                                                <a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
                                            </div>
                                            <div className="col-6 mb-0">
                                                <a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal custom-modal fade" id="edit_project" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="form-header">
                                        <h5 className="modal-title">Edit Project</h5>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>Project Name</label>
                                                    <input className="form-control" value="Gold App" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-0">
                                                <div className="form-group">
                                                    <label>Client</label>
                                                    <select className="select">
                                                        <option>Client 1</option>
                                                        <option>Client 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="submit-section">
                                            <button className="btn btn-primary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    );
}

export default Dashboard;