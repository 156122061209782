import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import Avatar from '../images/avt-1.png';
import { useParams, useNavigate } from 'react-router-dom';
import NotificationBox from '../components/NotificationBox'
import DataProjectServices from '../api/projects-api';
import DataService from '../api/services';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

function ProjectDetails() {
    let params = useParams();
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [boards, setBoards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { authTokens, } = useAuth();
    const [boardData, setBoardData] = useState({
        name: '',
        status: '',
        board_type: '',
        project: '',
    });

    useEffect(() => {
        const fetchProjectDetails = async () => {
            setIsLoading(true);
            try {
                const res = await DataProjectServices.getProjectById(authTokens, params.id);
                console.log(res.boards);
                setProject(res);
                setBoards(res.boards)

                setBoardData(prevBoardData => ({
                    ...prevBoardData,
                    project: params.id
                }));
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        if (params.id) {
            fetchProjectDetails();
        }
    }, [params.id, authTokens]);


    if (isLoading || !project) return <div>Loading...</div>;


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBoardData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(boardData);
        try {
            const response = await DataService.createBoards(authTokens, boardData);
            console.log('Board created:', response);


            if (response && response.id) {
                setBoards(prevBoards => [...prevBoards, response]);
            }


            setShowModal(false);
        } catch (error) {
            console.error('Error creating board:', error);

        }
    };

    console.log(boards)
    return (
        <div>
            <Header />

            <div className="main">
                <div className="main-content">
                    <NotificationBox />
                    <div className="row">
                        <div className="col-12">
                            <div className="box project">
                                <div className="box-header">
                                    <h4 className="box-title">{project.name}</h4>
                                    <div className="box-right d-flex">
                                        <div className="dropdown ml-14">
                                            <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className='bx bx-dots-vertical-rounded fs-22'></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                <a className="dropdown-item" href="#"><i className="bx bx-edit mr-5"></i>Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="box-body d-flex justify-content-between pb-0">
                                    <div className="team-name">
                                        <a href="#" className="team">
                                            <div className="icon"><i className="fas fa-tags"></i></div>
                                            <h5>Team Name</h5>
                                        </a>
                                    </div>
                                    <div className="action">
                                        <a href="#" className="comment">32 Comments</a>
                                        <a href="#" className="edit">Edit</a>
                                        <a href="#" className="invite"><i className="fas fa-user-plus mr-5"></i>Invite People</a>
                                        <a href="#" className="add">New<i className="fas fa-caret-down pl-12"></i></a>
                                    </div>
                                    <ul className="user-list s2">
                                        <li><img src={Avatar} alt="user" /></li>
                                        <li><img src={Avatar} alt="user" /></li>
                                        <li><img src={Avatar} alt="user" /></li>
                                        <li><img src={Avatar} alt="user" /></li>
                                        <li><img src={Avatar} alt="user" /></li>
                                        <li className="total"><span>+4</span></li>
                                    </ul>
                                </div>
                                <div className="divider"></div>
                                <div className="box-body content">
                                    <h4 className="title mb-4">Project Description</h4>

                                    <div className="project-description content">
                                        {/* Other content */}
                                        <div
                                            dangerouslySetInnerHTML={{ __html: project.description }}
                                        />
                                    </div>

                                    <div className="form-chat d-none">
                                        <form action="#" method="get" accept-charset="utf-8">
                                            <div className="message-form-chat">

                                                <span className="message-text">
                                                    <textarea name="message" placeholder="Type comment here" required="required"></textarea>
                                                </span>

                                                <span className="camera">
                                                    <a href="#" title="">
                                                        <i className="fas fa-smile"></i>
                                                    </a>
                                                </span>

                                                <span className="icon-message">
                                                    <a href="#" title="">
                                                        <i className="fas fa-paperclip"></i>
                                                    </a>
                                                </span>

                                                <div className="icon-mobile">
                                                    <ul>
                                                        <li>
                                                            <a href="#" title=""><i className="fas fa-smile"></i></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" title=""><i className="fas fa-paperclip"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <span className="btn-send">
                                                    <button className="waves-effect" type="submit">Send <i className="fas fa-paper-plane"></i></button>
                                                </span>


                                            </div>

                                            <div className="clearfix"></div>
                                        </form>

                                    </div>

                                    <div className="project-description mt-10 d-none">
                                        <h4>Project Description</h4>
                                        <div className="comment-box">
                                            <div className="comment d-flex">
                                                <div className="left d-flex">
                                                    <div className="comment-pic">
                                                        <img src={Avatar} alt="" />
                                                    </div>
                                                    <div className="comment-body">
                                                        <div className="name">
                                                            <h5 className="font-w600 fs-18">Elizabeth Holland</h5>
                                                            <p className="text mb-0 fs-18">Duis pretium gravida enim, vel maximus ligula fermentum a. Sed rhoncus eget ex id egestas. Nam nec nisl placerat, tempus erat a, condimentum metus.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="group-action mt-10">
                                                        <a href="#" className="like active"><i className="fas fa-thumbs-up"></i>34 Like</a>
                                                        <a href="#" className="reply"><i className="fas fa-reply-all"></i>Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="comment d-flex">
                                                <div className="left d-flex">
                                                    <div className="comment-pic">
                                                        <img src={Avatar} alt="" />
                                                    </div>
                                                    <div className="comment-body">
                                                        <div className="name">
                                                            <h5 className="font-w600 fs-18">Mike Palmer</h5>
                                                            <p className="text mb-0 fs-18">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo, quis tempor ligula. Quisque quis pharetra felis. Ut quis consequat orci, at consequat felis. Suspendisse auctor laoreet placerat.
                                                                Nam et risus non lacus dignissim lacinia sit amet nec eros.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="group-action mt-10">
                                                        <a href="#" className="like"><i className="fas fa-thumbs-up"></i>34 Like</a>
                                                        <a href="#" className="reply"><i className="fas fa-reply-all"></i>Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="comment rep d-flex">
                                                <div className="left d-flex">
                                                    <div className="comment-pic">
                                                        <img src={Avatar} alt="" />
                                                    </div>
                                                    <div className="comment-body">
                                                        <div className="name">
                                                            <h5 className="font-w600 fs-18">Beatrice Collins</h5>
                                                            <p className="text mb-0 fs-18">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo, quis tempor ligula. Quisque quis pharetra felis. Ut quis consequat orci.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="group-action mt-10">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="comment rep d-flex">
                                                <div className="left d-flex">
                                                    <div className="comment-pic">
                                                        <img src={Avatar} alt="" />
                                                    </div>
                                                    <div className="comment-body">
                                                        <div className="name">
                                                            <h5 className="font-w600 fs-18">Roger Meyer</h5>
                                                            <p className="text mb-0 fs-18">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo, quis tempor ligula. Quisque quis pharetra felis. Ut quis consequat orci.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="group-action mt-10">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divider mb-0"></div>
                                        </div>
                                    </div>
                                    <div className="divider"></div>

                                    <div class="box-header">
                                        <div class="me-auto">
                                            <h4 className="card-title mb-6 mt-4">{project.name} | Boards</h4>
                                            <p class="mb-0 fs-14 mt-9 ">Lorem ipsum dolor sit amet</p>
                                        </div> <Button variant="primary" onClick={() => setShowModal(true)}>
                                            Create New Board
                                        </Button>
                                    </div>

                                    <div class="box-body pt-20">
                                        <Container fluid="md">
                                            <Row>


                                                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Create New Board</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>
                                                            <Form.Group controlId="formBoardName">
                                                                <Form.Label>Board Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="name"
                                                                    value={boardData.name}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter board name"
                                                                />
                                                            </Form.Group>

                                                            <Form.Group controlId="formBoardStatus">
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    name="status"
                                                                    value={boardData.status}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    <option value="enabled">Enabled</option>
                                                                    <option value="disabled">Disabled</option>
                                                                </Form.Control>
                                                            </Form.Group>

                                                            <Form.Group controlId="formBoardType">
                                                                <Form.Label>Board Type</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="board_type"
                                                                    value={boardData.board_type}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter board type"
                                                                />
                                                            </Form.Group>

                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={handleSubmit}>
                                                            Save Changes
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                {boards.map((board, index) => (
                                                    <Col key={board.id} md={4} className="box box-carousel">
                                                        <div className="card-top">
                                                            <div className="sm-f-wrap d-flex">
                                                                <h5 className="icon-gold text-white bg-green">CE</h5>
                                                                <Link to={`/board/${board.id}`} className="h5 t-title">
                                                                    <span>{board.name}</span>
                                                                </Link>
                                                            </div>
                                                           
                                                        </div>

                                                        <div className="card-center">
                                                            <h6 className="font-w400 fs-16">Task Done: 23/45</h6>
                                                            <div className="progress skill-progress mb-23" style={{ height: '7px', width: '80%' }}>
                                                                <div className="progress-bar bg-primary progress-animated" style={{ height: '7px', width: '52%' }} role="progressbar"></div>
                                                            </div>
                                                            <div className="sm-f-wrap d-flex justify-content-between">
                                                                <ul className="user-list">
                                                                    <li><img src={Avatar} alt="user" /></li>
                                                                 
                                                                </ul>
                                                                <ul className="tf-icon-list">
                                                                    <li><a href="#"><i className='bx bx-calendar'></i></a></li>
                                                                    <li><a href="#"><i className='bx bxs-star' ></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}

                                            </Row>
                                        </Container>



                                    </div>




                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>

    );
}

export default ProjectDetails;