import { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'

const API_BASE = process.env.REACT_APP_API_BASE_URL
const AuthContext = createContext()

async function fetchUser(token) {
  const url = API_BASE + '/auth/users/me/';
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Token ${token}`,
    }
  })
}

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()

  const [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? localStorage.getItem('authTokens') : null))
  const [authTokens, setAuthTokens] = useState(() => (typeof window !== "undefined" ? JSON.parse(window.localStorage.getItem('authTokens')) : null))
  const [loading, setLoading] = useState(true)

  const initUser = async token => {
    const resp = await fetchUser(token)
    const user = await resp.json()
    setUser(user)
    if (loading) {
      setLoading(false)
    }
    return user;
  }

  const login = async (username, password) => {
    const response = await fetch(API_BASE + "/auth/token/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    let data = await response.json();
    if (data.auth_token) {
      localStorage.setItem('authTokens', JSON.stringify(data.auth_token));
      setAuthTokens(data.auth_token)
      const user = await initUser(data.auth_token)
      if (!user.full_name) {
        navigate('/sign-up');
      } else {
        navigate('/')
      }
    } 
  }

  const logout = () => {
    // e.preventDefault()
    localStorage.removeItem('authTokens')
    setAuthTokens(null)
    setUser(null)
    navigate('/user-login')
  }

  const contextData = {
    user,
    authTokens,
    login,
    logout,
  }

  useEffect(() => {
    if (loading) {
      const token = JSON.parse(localStorage.getItem('authTokens'));
      if (token) {
        setAuthTokens(token);
        initUser(token);
      }
    }
  }, [loading])


  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  )
}
export const useAuth = () => useContext(AuthContext)