import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import DataServices from '../api/services';
import Logo from '../images/pinkrobot-dark.png';

const WelcomePage = () => {
    const { uid, token } = useParams();
    DataServices.activateUser(uid, token);

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
        <Row className="justify-content-center">
            <Col md={6} className="text-center">
                <img src={Logo} className="mb-4" alt="Logo" width="300px" />
                <h2 className='my-4'>Thank you for activating your account.</h2>
                <Button href="/user-login" variant="primary">Login here</Button>
            </Col>
        </Row>
    </Container>
    );
};

export default WelcomePage;