import React, { useEffect, useState } from "react";
import NotificationBox from "../components/NotificationBox";
import AddClient from "../components/Add-Client";
import Header from '../components/Header';
import DataClients from '../api/clients-api';
import { useAuth } from '../context/AuthContext';
import _ from 'lodash';

function Clients() {
    // State to store the list of clients
    const [clients, setClients] = useState([]);
    const [clientDetails, setClientDetails] = useState([]);
    const { authTokens } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await DataClients.clientsList(authTokens);
                setClients(res.results);

                const clientsObj = res.results;
                const fetchDetailPromises = clientsObj.map(async (item) => {
                    try {
                        const response = await DataClients.clientDetailsById(authTokens, item.user);
                        const workspace = await DataClients.getClientWorkspace(authTokens, item.user);
                        return { ...item, detail: response, workspace: workspace.name };
                    } catch (error) {
                        console.error(`Error fetching details for item ${item.user}:`, error);
                        return { ...item, detail: null, error: true };
                    }
                });

                const detailedResults = await Promise.all(fetchDetailPromises);
                setClientDetails(detailedResults);
                console.log("clientDetails: " + fetchDetailPromises)
            } catch (err) {
                console.error(err);
            }
        };

        if (authTokens) {
            fetchData();
        }
    }, [authTokens]);

    return (
        <div>
            <Header />
            <div className="main">
                <div className="main-content board">
                    <NotificationBox />
                    <div className="row">
                        <div className="col-12">
                            <div className="box">
                                <div className="box-body d-flex justify-content-between pt-0 pb-0">
                                    <div className="search-form d-flex">
                                        <input type="text" placeholder="Client Name" className="form-control" />
                                        <select className="form-control" id="inputState">
                                            <option value="" selected>Select Company Name</option>
                                            <option>Company 1</option>
                                            <option>Company 2</option>
                                        </select>
                                        <button type="submit" className="search d-flex"><i className="fas fa-search"></i>Search</button>
                                    </div>
                                    <div className="list-action">
                                        <a className="list" href="#" onclick="liststyle()"><i className='bx bx-menu' ></i></a>
                                        <a href="#" className="list-board active" onclick="listboard()"><i className='bx bxs-dashboard' ></i></a>
                                        <AddClient />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Dynamic client list items */}
                        {clients.map((client, index) => (
                            <div className="col-3 col-md-6 col-sm-12 mb-25" key={client.id}>
                                <div className="box client">
                                    <div className="dropdown">
                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='bx bx-dots-horizontal-rounded'></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="bx bx-trash"></i> Delete</a>
                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="bx bx-edit mr-5"></i>Edit</a>
                                        </div>
                                    </div>
                                    <div className="box-body pt-5 pb-0">
                                        <div className="img-box">
                                            <img src="./userImgPlaceholder.png" alt="Client" />
                                            <div className="pulse-css"></div>
                                        </div>
                                        <a href={`/client-details/${client.id}`}><h5 className="mt-17">{client.name}</h5></a>
                                        {clientDetails[index]?.detail?.user_details?.role ? (
                                            <p className="fs-14 font-w400 font-main">
                                                {_.capitalize(clientDetails[index].detail.user_details.role)} at 
                                                <span className="text-clo-primary font-w500 pl-4">{_.capitalize(clientDetails[index].workspace)}</span>
                                            </p>
                                        ) : (
                                            <p className="fs-14 font-w400 font-main">
                                                Default Role at 
                                                <span className="text-clo-primary font-w500 pl-4">Company Name</span>
                                            </p>
                                        )}
                                        <ul className="info">
                                            {clientDetails[index]?.detail?.user_details?.phone_no ? (
                                                <li className="fs-14"><i className='bx bxs-phone'></i>{clientDetails[index].detail.user_details.phone_no}</li>
                                            ) : (
                                                <li className="fs-14"><i className='bx bxs-phone'></i>Unavailable</li>
                                            )}
                                            {clientDetails[index]?.detail?.email ? (
                                                <li className="fs-14"><i className='bx bxs-envelope'></i>{clientDetails[index].detail.email}</li>
                                            ) : (
                                                <li className="fs-14"><i className='bx bxs-envelope'></i>Unavailable</li>
                                            )}
                                        </ul>
                                        <div className="group-btn d-flex justify-content-between">
                                            <a className="bg-btn-pri color-white" href="#">Message</a>
                                            <a className="bg-btn-sec color-main" href={`/client-details/${client.id}`}>View Profile</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clients;