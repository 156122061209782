import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProfilePhoto from '../images/profile.png';
import { useAuth } from "../context/AuthContext";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faWallet, faWrench, faLock, faPowerOff, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Header({ children }) {
    const capitalize = (str) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const { logout, user } = useAuth();
    const [isMobile, setIsMobile] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({});

     // Function to detect if the viewport width is less than 768px (example for mobile view)
     const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 1200);
    };

    const handleResize = () => {
        checkIsMobile();
    };

    // Effect to add resize event listener and check initial state
    useEffect(() => {
        checkIsMobile(); // Check initial state
        window.addEventListener('resize', handleResize);

        // Clean up function to remove event listener
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures this effect runs only once

    // Function to toggle sidebar visibility and add class for mobile
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);

        if (isMobile) {
        // Add or remove class from div with classname 'sidebar'
        const sidebarDiv = document.querySelector('.sidebar-expand');
        sidebarDiv.classList.add('active'); // Toggle 'mobile-open' class
        document.body.classList.add('overlay-sidebar');
        } else {
            const sidebarDiv = document.querySelector('.sidebar-expand');
            sidebarDiv.classList.remove('active');
        }
    };

    const toggleDropdown = (key) => {
        setDropdownOpen(prevState => ({
            ...prevState, // Copy the existing state
            [key]: !prevState[key] // Toggle the current dropdown state
        }));
    };
    const location = useLocation();

    // Function to get page title based on current route
    const getPageTitle = () => {
        const pathname = location.pathname;
        if (pathname === '/') {
            return 'Dashboard';
        } else if (pathname === '/user-profile') {
            return 'User Profile';
        } else if (pathname === '/new-project') {
            return 'New Project';
        } else if (pathname === '/project') {
            return 'Projects';
        } else if (pathname === '/clients') {
            return 'Clients';
        } else if (pathname === '/calendar') {
            return 'Calendar';
        } else if (pathname === '/add-client') {
            return 'Add Client';
        } else if (/\/client-details\/\d+/.test(pathname)) {
            return 'Client Details';
        } else {
            return 'Dashboard';
        }
        // switch (location.pathname) {
        // case '/':
        //     return 'Dashboard';
        // case '/user-profile':
        //     return 'User Profile';
        // case '/new-project':
        //     return 'New Project';
        // case '/project':
        //     return 'Projects';
        // case '/clients':
        //     return 'Clients';
        // case '/calendar':
        //     return 'Calendar';
        // case '/add-client':
        //     return 'Add Client';
        // default:
        //     return 'Dashboard';
        // }
    };
    return (
        <div>
            <div className="main-header">
                <div className="d-flex align-items-center">
                    <div className="mobile-toggle" id="mobile-toggle" onClick={toggleSidebar}>
                        <i className='bx bx-menu'></i>
                    </div>
                    <div className="main-title">
                        {getPageTitle()}
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    {/* <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <input type="text" className="form-control" placeholder="Search" />
                            <span className="bx bx-search-alt"></span>
                        </div>
                    </form>
                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className='bx bx-search-alt' ></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">

                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary h-100" type="submit"><i className='bx bx-search-alt' ></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="btn dropdown-toggle" id="header-lang-img">
                                EN
                                <i className='bx bx-caret-down'></i>
                            </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">


                            <a href="javascript:void(0);" className="dropdown-item notify-item language" data-lang="en">
                                <img src="./images/flags/us.jpg" alt="user-image" className="me-1" height="12" /> <span className="align-middle">English</span>
                            </a>

                            <a href="javascript:void(0);" className="dropdown-item notify-item language" data-lang="sp">
                                <img src="./images/flags/spain.jpg" alt="user-image" className="me-1" height="12" /> <span className="align-middle">Spanish</span>
                            </a>


                            <a href="javascript:void(0);" className="dropdown-item notify-item language" data-lang="gr">
                                <img src="./images/flags/germany.jpg" alt="user-image" className="me-1" height="12" /> <span className="align-middle">German</span>
                            </a>


                            <a href="javascript:void(0);" className="dropdown-item notify-item language" data-lang="it">
                                <img src="./images/flags/italy.jpg" alt="user-image" className="me-1" height="12" /> <span className="align-middle">Italian</span>
                            </a>


                            <a href="javascript:void(0);" className="dropdown-item notify-item language" data-lang="ru">
                                <img src="./images/flags/russia.jpg" alt="user-image" className="me-1" height="12" /> <span className="align-middle">Russian</span>
                            </a>
                        </div>
                    </div> */}
                    {/* <div className="dropdown d-none d-lg-inline-block ms-1">
                        <button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                                <i className="bx bx-fullscreen"></i>
                            </button>
                    </div> */}
                    <div className="dropdown d-inline-block mt-12">
                        <Dropdown as="div" className="d-inline-block mt-12">
                            <Dropdown.Toggle as="a" className="btn header-item waves-effect" id="page-header-user-dropdown">
                                <img className="rounded-circle header-profile-user" src={ProfilePhoto} alt="Header Avatar" />
                                <span className="pulse-css"></span>
                                <span className="info d-xl-inline-block color-span">
                                    <span className="d-block fs-20 font-w600">{capitalize(user.full_name || user.username)}</span>
                                    <span className="d-block mt-7">{user.email}</span> {/* Adjust email dynamically if needed */}
                                </span>

                            </Dropdown.Toggle>

                            <Dropdown.Menu align="end">
                                <Dropdown.Item eventKey="1"><FontAwesomeIcon icon={faUser} className="me-2" />Profile</Dropdown.Item>
                                {/* <Dropdown.Item eventKey="2"><FontAwesomeIcon icon={faWallet} className="me-2" />My Wallet</Dropdown.Item> */}
                                <Dropdown.Item eventKey="3"><span className="badge bg-success float-end">11</span><FontAwesomeIcon icon={faWrench} className="me-2" />Settings</Dropdown.Item>
                                {/* <Dropdown.Item eventKey="4"><FontAwesomeIcon icon={faLock} className="me-2" />Lock screen</Dropdown.Item> */}
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="5" onClick={logout}><FontAwesomeIcon icon={faPowerOff} className="me-2 text-danger" />Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {children}
        </div>

    )

}

export default Header;