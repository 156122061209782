import React, { useState, useEffect } from 'react';
import DataServices from '../api/services';
import { useAuth } from '../context/AuthContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { EditorState, ContentState, convertFromHTML, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Tabs, Tab, Form, Button, Container, Modal, ListGroup, InputGroup, Row, Col, FormControl, Dropdown } from 'react-bootstrap';
import { faEllipsisH, faPencilAlt, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Project1 from '../images/to-do-list/project-1.png';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Card({ boards }) {
  const { authTokens, user } = useAuth();
  const [collaborator, setCollaborator] = useState([]);
  const [modifiedCollaborators, setModifiedCollaborators] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [cards, setCards] = useState(boards.cards || []);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [selectedCardDesc, setSelectedCardDesc] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [isEditFileMode, setIsEditFileMode] = useState(false);
  const [newCardDesc, setNewCardDesc] = useState(() =>
    EditorState.createEmpty()
  );
  const [cardData, setCardData] = useState({
    name: '',
    description: '',
    time_tracking: 'test',
    priority: '',
    tags: '',
    category: '',
    todo_list: [],
    checklist: [],
    collaborators: '',
    board: boards.id,
    invoice: null,
    due_date: '',
    comments: '',
  });
  const [show, setShow] = useState(false);
  const [showCardEdit, setShowCardEdit] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [replyText, setReplyText] = useState('');
  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [expandedReplies, setExpandedReplies] = useState({});


  const initialColumns = {
    todo: {
      name: "To Do",
      items: cards.filter(card => card.category === "todo")
    },
    inProgress: {
      name: "In Progress",
      items: cards.filter(card => card.category === "inProgress")
    },
    inReview: {
      name: "In Review",
      items: cards.filter(card => card.category === "inReview")
    },
    approved: {
      name: "Approved",
      items: cards.filter(card => card.category === "approved")
    }
  };

  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const data = await DataServices.getCollaborators(authTokens);
        if (Array.isArray(data.results)) {
          let getAllCollaborators = [];
          data.results.map((col) => {
              getAllCollaborators.push({value: col.id, label: (<div><span className="avatar">{col.name.slice(0, 1)}{col.name.split(" ").splice(-1)[0].slice(0,1)}</span> {col.name}</div>)});
          });
          setModifiedCollaborators(getAllCollaborators);
          setCollaborator(data.results);
        } else {
          console.error('Expected an array of workspaces, received:', data.results);
        }
      } catch (error) {
        console.error('Error fetching workspaces:', error);
      }
    };

    fetchWorkspaces();
  }, []);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const data = await DataServices.getInvoice(authTokens);
        if (Array.isArray(data.results)) {
          setInvoice(data.results);
        } else {
          console.error('Expected an array of workspaces, received:', data.results);
        }
      } catch (error) {
        console.error('Error fetching workspaces:', error);
      }
    };

    fetchWorkspaces();
  }, []);

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        if (selectedCard && selectedCard.id) {
          const res = await DataServices.getCardAttachments(authTokens);
          const attachmentById = res.results.filter(attachment => attachment.card === selectedCard.id);

          setPreviews(attachmentById.map(attachment => ({
            id: attachment.id,
            previewUrl: attachment.file,
            fileType: getFileTypeFromUrl(attachment.file),
          })));
        }
      } catch (error) {
        console.error("Error fetching attachments:", error);
      }
    };

    if (authTokens && selectedCard?.id) {
      fetchAttachments();
    }
  }, [authTokens, selectedCard, cards]);

  const isValidJSON = str => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleClose = () => {
    setIsEditDescription(false);
    setShow(false);
    setShowCardEdit(false);
    setNewCardDesc(EditorState.createEmpty());
  };
  const handleShow = () => {
    setSelectedCard(null);
    setShow(true);
  };

  const handleShowCardDetails = (card) => {
    setSelectedCard(card);
    setShowCardEdit(true);

    if(card.collaborators) {
      let getSelectedCollaborators = [];
      card.collaborators.map((col) => {
        getSelectedCollaborators.push({value: col.id, label: (<div><span className="avatar">{col.name.slice(0, 1)}{col.name.split(" ").splice(-1)[0].slice(0,1)}</span> {col.name}</div>)});
      });
      setSelectedCollaborator(getSelectedCollaborators);
    }
    if(card.description) {
      if(isValidJSON(card.description)) {
        setSelectedCardDesc(EditorState.createWithContent(convertFromRaw(JSON.parse(card.description))));
      }else {
        setSelectedCardDesc(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(card.description)
          )
        ));
      }
    }else {
      setSelectedCardDesc(EditorState.createEmpty());
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeCollaborator = (event) => {
    let newCollab = [];
    event.forEach(val => {
      newCollab.push(val.value);
    });
    setCardData(prevState => ({
      ...prevState,
      collaborators: newCollab,
    }));
  };

  const handleAddTodoItem = () => {
    const newTodo = { text: '', completed: false };
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        todo_list: [...prev.todo_list, newTodo],
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        todo_list: [...prev.todo_list, newTodo],
      }));
    }
  };

  const handleUpdateTodoItem = (index, newText) => {
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        todo_list: prev.todo_list.map((item, idx) => idx === index ? { ...item, text: newText } : item),
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        todo_list: prev.todo_list.map((item, idx) => idx === index ? { ...item, text: newText } : item),
      }));
    }
  };

  const toggleTodoCompletion = (index) => {
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        todo_list: prev.todo_list.map((item, idx) => idx === index ? { ...item, completed: !item.completed } : item),
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        todo_list: prev.todo_list.map((item, idx) => idx === index ? { ...item, completed: !item.completed } : item),
      }));
    }
  };

  const handleRemoveTodoItem = (indexToRemove) => {
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        todo_list: prev.todo_list.filter((_, idx) => idx !== indexToRemove),
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        todo_list: prev.todo_list.filter((_, idx) => idx !== indexToRemove),
      }));
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);

    const newPreviews = selectedFiles.map(file =>
      Object.assign(file, {
        previewUrl: URL.createObjectURL(file),
        fileType: file.type.includes('image/') ? 'image' : file.type.includes('pdf') ? 'pdf' : 'unknown',
      })
    );
    setPreviews(prev => [...prev, ...newPreviews]);
  };

  const handleRemoveFile = async (index) => {
    const attachmentToDelete = previews[index];

    if (attachmentToDelete?.id) {
      try {
        await DataServices.deleteCardAttachments(authTokens, attachmentToDelete.id);
        const updatedPreviews = previews.filter((_, i) => i !== index);
        setPreviews(updatedPreviews);
      } catch (error) {
        console.error(`Failed to delete attachment ${attachmentToDelete.id}:`, error);
      }
    }
  };

  const getFileTypeFromUrl = (url) => {
    if (!url) return 'unknown';

    const extensionMatch = url.match(/\.([0-9a-z]+)([\?#]|$)/i);
    if (!extensionMatch) return 'unknown';

    const extension = extensionMatch[1].toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return 'image';
    } else if (extension === 'pdf') {
      return 'pdf';
    }
    return 'unknown';
  };

  const renderFilePreviews = () => (
    <div className="d-flex flex-wrap">
      {previews.map((file, index) => (
        <div key={index} className="file-preview position-relative me-2 mb-2">
          {file.fileType === 'image' ? (
            <img src={file.previewUrl} alt="Preview" className="img-fluid preview-image" onClick={() => handleImageClick(file.previewUrl)} style={{ cursor: 'pointer' }} />
          ) : file.fileType === 'pdf' ? (
            <div className="pdf-preview d-flex justify-content-center align-items-center">
              <a href={file.previewUrl} target="_blank" rel="noopener noreferrer">PDF</a>
            </div>
          ) : (
            <p>Unsupported file type or missing file information</p>
          )}
          <Button variant="danger" className="btn-remove-file position-absolute top-0 end-0" onClick={() => handleRemoveFile(index)}>
            <i className='bx bx-x'></i>
          </Button>
        </div>
      ))}
    </div>
  );

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleAddChecklistItem = () => {
    const newChecklistItem = { text: '', checked: false };
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        checklist: [...prev.checklist, newChecklistItem],
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        checklist: [...prev.checklist, newChecklistItem],
      }));
    }
  };

  const handleChecklistItemChange = (index, value, property) => {
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        checklist: prev.checklist.map((item, idx) =>
          idx === index ? { ...item, [property]: value } : item
        ),
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        checklist: prev.checklist.map((item, idx) =>
          idx === index ? { ...item, [property]: value } : item
        ),
      }));
    }
  };

  const handleEditButtonClick = () => {
    if (isEditMode) {
      handleSaveUpdates();
    } else {
      setIsEditMode(true);
    }
  };

  const handleRemoveChecklistItem = (index) => {
    if (isEditMode) {
      setSelectedCard(prev => ({
        ...prev,
        checklist: prev.checklist.filter((_, idx) => idx !== index),
      }));
    } else {
      setCardData(prev => ({
        ...prev,
        checklist: prev.checklist.filter((_, idx) => idx !== index),
      }));
    }
  };

  const handleSaveUpdates = async () => {
    try {
      const updateTodoItems = await DataServices.updateTodos(selectedCard.id, authTokens, selectedCard.todo_list);
      const updateChecklistsItems = await DataServices.updateChecklists(selectedCard.id, authTokens, selectedCard.checklist);

      setIsEditMode(false);

    } catch (error) {
      console.error('Failed to update card:', error);
    }
  };

  const toggleEditDescription = () => {
    setEditDescription(selectedCard.description || "");
    setIsEditDescription(!isEditDescription);
  };

  const handleChangeTitle = async(event, cardId) => {
    const newTitle = event.target.value;
    setSelectedCard(prevCard => ({ ...prevCard, name: newTitle }));
    try {
      const updatedCard = await DataServices.updateCard(cardId, authTokens, JSON.stringify({ name: newTitle }));
      const updatedCards = cards.map(card => card.id === cardId ? { ...card, name: updatedCard.name } : card);
      setCards(updatedCards);

      const newColumns = { ...columns };
      Object.keys(newColumns).forEach(columnId => {
        newColumns[columnId].items = newColumns[columnId].items.filter(item => item.id !== cardId);
      });

      const columnKey = getCategoryColumnKey(updatedCard.category);
      newColumns[columnKey].items.push(updatedCard);

      setColumns(newColumns);
    } catch (error) {
      console.error('Failed to update card name:', error);
    }
  };

  const handleUpdateDescription = async () => {
    try {
      const updatedCard = await DataServices.updateCardDescription(selectedCard.id, authTokens, JSON.stringify(convertToRaw(selectedCardDesc.getCurrentContent())));
      setSelectedCard(updatedCard);
      setIsEditDescription(false);
      const updatedCards = cards.map(card => card.id === selectedCard.id ? { ...card, description: JSON.stringify(convertToRaw(selectedCardDesc.getCurrentContent())) } : card);
      setCards(updatedCards);

      const newColumns = { ...columns };
      Object.keys(newColumns).forEach(columnId => {
        newColumns[columnId].items = newColumns[columnId].items.filter(item => item.id !== selectedCard.id);
      });

      const columnKey = getCategoryColumnKey(selectedCard.category);
      newColumns[columnKey].items.push(updatedCard);

      setColumns(newColumns);
    } catch (error) {
      console.error('Failed to update card description:', error);

    }
  };

  const handleCollabChange = async (event, cardId) => {
    let newCollab = [];
    event.forEach(val => {
      newCollab.push(val.value);
    });
    setSelectedCard(prevCard => ({ ...prevCard, collaborators: newCollab }));
    try {
      const updatedCard = await DataServices.updateCard(cardId, authTokens, JSON.stringify({ collaborators: newCollab }));
      const updatedCards = cards.map(card => card.id === cardId ? { ...card, collaborators: updatedCard.collaborators } : card);
      setCards(updatedCards);

      const newColumns = { ...columns };
      Object.keys(newColumns).forEach(columnId => {
        newColumns[columnId].items = newColumns[columnId].items.filter(item => item.id !== cardId);
      });

      const columnKey = getCategoryColumnKey(updatedCard.category);
      newColumns[columnKey].items.push(updatedCard);

      setColumns(newColumns);
    } catch (error) {
      console.error('Failed to update card collaborators:', error);
    }
  };

  const handleUpdateTags = async (event, cardId) => {
    const newTags = event.target.value;
    setSelectedCard(prevCard => ({ ...prevCard, tags: newTags }));

    try {
      const updatedCard = await DataServices.updateCardTag(cardId, authTokens, newTags);
      const newColumns = { ...columns };
      Object.keys(newColumns).forEach(columnId => {
        newColumns[columnId].items = newColumns[columnId].items.filter(item => item.id !== cardId);
      });

      const columnKey = getCategoryColumnKey(updatedCard.category);
      newColumns[columnKey].items.push(updatedCard);

      setColumns(newColumns);
    } catch (error) {
      console.error('Failed to update card tags:', error);
    }
  };

  const handleNewCardDesc = (e) => {
    setNewCardDesc(e);
    setCardData(prevState => ({
      ...prevState,
      description: JSON.stringify(convertToRaw(e.getCurrentContent())),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose()
    cardData.board = boards.id;

    try {
      const response = await DataServices.createCards(authTokens, cardData);

      if (response && response.id) {

        const newCard = response;
        const category = newCard.category;

        setColumns(prevColumns => {
          const newColumns = { ...prevColumns };
          const columnKey = getCategoryColumnKey(category);
          if (newColumns[columnKey]) {
            newColumns[columnKey].items = [...newColumns[columnKey].items, newCard];
          }
          return newColumns;
        });
      }

    } catch (error) {
      console.error('An error occurred:', error);

    }
  };

  function getCategoryColumnKey(category) {
    switch (category) {
      case 'todo': return 'todo';
      case 'inProgress': return 'inProgress';
      case 'inReview': return 'inReview';
      case 'approved': return 'approved';
      default: return '';
    }
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    let updatedColumns = { ...columns };
    const cardBeingMoved = { ...columns[source.droppableId].items[source.index] };
    const newStatus = destination.droppableId;

    if (source.droppableId !== destination.droppableId) {

      try {
        const response = await DataServices.updateCardStatus(cardBeingMoved.id, newStatus, authTokens);
        if (response) {

          const sourceItems = Array.from(updatedColumns[source.droppableId].items);
          const destItems = Array.from(updatedColumns[destination.droppableId].items);
          sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, { ...cardBeingMoved, category: newStatus });

          updatedColumns[source.droppableId].items = sourceItems;
          updatedColumns[destination.droppableId].items = destItems;


          if (selectedCard && selectedCard.id === cardBeingMoved.id) {
            setSelectedCard({ ...selectedCard, category: newStatus });
          }
        }
      } catch (error) {
        console.error("Error updating card status:", error);
      }
    } else {

      const items = Array.from(updatedColumns[source.droppableId].items);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      updatedColumns[source.droppableId].items = items;
    }

    setColumns(updatedColumns);
  };

  const getColumnClassName = (columnId) => {
    switch (columnId) {
      case 'todo':
        return "kanban-list kanban-list-to-do";
      case 'inProgress':
        return "kanban-list kanban-progress";
      case 'inReview':
        return "kanban-list kanban-review";
      case 'approved':
        return "kanban-list kanban-approved";
      default:
        return "kanban-list";
    }
  };

  const handleStatusChange = async (e, cardId) => {
    const newStatus = e.target.value;

    try {

      const updatedCardResponse = await DataServices.updateCardStatus(cardId, newStatus, authTokens);
      if (updatedCardResponse && updatedCardResponse.id) {

        const updatedCard = updatedCardResponse;
        const updatedCards = cards.map(card => card.id === cardId ? { ...card, category: newStatus } : card);
        setCards(updatedCards);

        if (selectedCard && selectedCard.id === cardId) {
          setSelectedCard({ ...selectedCard, category: newStatus });
        }

        const newColumns = { ...columns };
        Object.keys(newColumns).forEach(columnId => {

          newColumns[columnId].items = newColumns[columnId].items.filter(item => item.id !== cardId);
        });

        const columnKey = getCategoryColumnKey(newStatus);
        newColumns[columnKey].items.push(updatedCard);

        setColumns(newColumns);
      }
    } catch (error) {
      console.error('Failed to update card status:', error);
    }
  };


  const handleAttachmentSubmit = async (e) => {
    e.preventDefault();
    setIsEditFileMode(false);
    setFiles(false)
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });
    formData.append('card', selectedCard.id);
    try {
      const response = await DataServices.cardAttachments(authTokens, formData);
    } catch (error) {
      console.error('An error occurred during attachment upload:', error);
    }

  };

  const deleteCard = async () => {
    if (cardToDelete) {
      try {
        await DataServices.deleteCardId(authTokens, cardToDelete);
        console.log(`Card ${cardToDelete} deleted successfully`);


        let found = false;
        let updatedColumns = { ...columns };
        for (const columnId in updatedColumns) {
          const index = updatedColumns[columnId].items.findIndex(item => item.id === cardToDelete);
          if (index > -1) {
            updatedColumns[columnId].items.splice(index, 1);
            found = true;
            break;
          }
        }

        if (found) {
          setColumns(updatedColumns);
        }

        setCardToDelete(null);
        toggleDeleteModal();
      } catch (error) {
        console.error(`Failed to delete card ${cardToDelete}:`, error);
      }
    }
  };

  useEffect(() => {
    const fetchCardDetails = async (cardId) => {
      try {
        const cardData = await DataServices.getCardById(cardId, authTokens);
        setSelectedCard(cardData);
      } catch (error) {
        console.error('Error fetching card details:', error);
      }
    };
    if (showCardEdit && selectedCard?.id) {
      fetchCardDetails(selectedCard.id);
    }
  }, [showCardEdit, selectedCard?.id, authTokens]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      const newCommentObj = await DataServices.addCommentToCard(selectedCard.id, newComment.trim(), user.id, authTokens);
      setNewComment("");
      setSelectedCard(prevCard => ({
        ...prevCard,

        comments: [...prevCard.comments, newCommentObj]
      }));
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleAddReply = async (commentId) => {
    if (!replyText.trim()) return;

    try {
      const newReply = await DataServices.addReplyToComments(selectedCard.id, commentId, replyText, user.id, authTokens);
      setSelectedCard(prevCard => {
        const updatedComments = prevCard.comments.map(comment => {
          if (comment.id === commentId) {
            return { ...comment, replies: [...(comment.replies || []), newReply] };
          }
          return comment;
        });
        return { ...prevCard, comments: updatedComments };
      });

      setReplyText('');
      setReplyingToCommentId(null);
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    await DataServices.deleteComment(commentId, authTokens)
    setSelectedCard(prevCard => {
      return {
        ...prevCard,
        comments: prevCard.comments.filter(comment => comment.id !== commentId)
      };
    });
  }

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='row'>
          <div className="kanban-board">
            <div className="kanban-board card mb-0 pd-0">
              <div className="box-body pd-0">
                <div className="kanban-cont">
                  {Object.entries(columns).map(([columnId, column]) => (
                    <Droppable key={columnId} droppableId={columnId}>
                      {(provided) => (
                        <div className={getColumnClassName(columnId)} {...provided.droppableProps} ref={provided.innerRef}>
                          <div className='kanban-header'>
                            <h6 className="card-title">{column.name}</h6>

                          </div>
                          <div className="kanban-wrap">
                            {column.items.map((item, index) => (
                              <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                {(provided) => (<div className="panel">
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="kanban-box item-box"
                                    onClick={() => handleShowCardDetails(item)}>
                                    <div className="dropdown edit" onClick={(e) => e.stopPropagation()}>
                                      <Dropdown>
                                        <Dropdown.Toggle className="btn-link" variant="link" bsPrefix="p-0">
                                          <FontAwesomeIcon icon={faEllipsisH} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right" align="end">
                                          <Dropdown.Item
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setCardToDelete(item.id);
                                              toggleDeleteModal();
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faTrash} className="mr-5" /> Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <div className='kanban-header' >
                                      <div className="card-title">
                                        {/* {item.name} */}
                                      </div>
                                    </div>
                                    <div className="img-box">
                                      <img src={Project1} alt="" />
                                    </div>

                                    <div className="content-box">
                                      <div className="progress skill-progress mt-15 mb-15" style={{ height: '7px' }}>
                                        <div className="progress-bar progress-animated" style={{ width: '78%', height: '7px' }} role="progressbar">
                                          <span className="sr-only">78% Complete</span>
                                        </div>
                                      </div>
                                      <h6 className="title">{item.name}</h6>
                                      <div className="d-flex justify-content-between">
                                        <div className="link"><a href="#"><i className="fas fa-paperclip"></i></a></div>
                                        <div className="time">
                                          <p className="font-main mb-0"><i className="far fa-clock"></i>Due in 5 days</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          {provided.placeholder}
                          <div className="btn-add-card">
                            <a className="dropdown-item font-w500 fs-16"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                handleShow();
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="mr-14" />Add a card
                            </a>
                          </div>
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>

      <Modal className="modal custom-modal fade" id="delete_project" role="dialog" show={showDeleteModal} onHide={toggleDeleteModal} centered>
        <div className="modal-content">
          <Modal.Body className="modal-body">
            <div className="form-header">
              <h3>Delete Card</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="row">
                <div className="col-6 mb-0">
                  <Button className="btn btn-primary continue-btn" onClick={deleteCard}>Delete</Button>
                </div>
                <div className="col-6 mb-0">
                  <Button className="btn btn-primary cancel-btn" onClick={toggleDeleteModal}>Cancel</Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal show={showCardEdit} onHide={handleClose} centered dialogClassName="w-80" >
        <Modal.Header closeButton />
        <Modal.Body>
          {selectedCard ? (
            <div className='card-body'>
              <Modal.Title className="mb-20">
                <Form.Control className="input-card-title" type="text" placeholder="Card Details" value={selectedCard ? `${selectedCard.name}` : ""} onChange={(e) => handleChangeTitle(e, selectedCard.id)}></Form.Control>
              </Modal.Title>
              <Row>
                <Col>
                  <Row>
                    <Col md={4} sm={6} className="mb-3">
                      <Form.Group>
                        <Form.Label className="label">Collaborators:</Form.Label>
                        <Select
                          defaultValue={selectedCollaborator}
                          placeholder="Select collaborators"
                          options={modifiedCollaborators}
                          isMulti={true}
                          name="collaborators"
                          className="pr-select-container pr-select-container--avatar"
                          classNamePrefix="pr-select"
                          onChange={(e) => handleCollabChange(e, selectedCard.id)}
                        />
                      </Form.Group>
                    </Col>


                    <Col md={4} sm={6} className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label className="label">Category:</Form.Label>
                        <Form.Select
                          className="status-select"
                          value={selectedCard.category}
                          onChange={(e) => handleStatusChange(e, selectedCard.id)}
                        >
                          <option value="todo">To Do</option>
                          <option value="inProgress">In Progress</option>
                          <option value="inReview">In Review</option>
                          <option value="approved">Approved</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label className="bold-label">Description:</Form.Label>
                      {isEditDescription ? (
                        <Button className="edit-button" onClick={handleUpdateDescription}>Save</Button>
                      ) : (
                        <Button className="edit-button" onClick={toggleEditDescription}>Edit</Button>
                      )}
                    </div>
                    {isEditDescription ? (
                      <Editor
                        editorState={selectedCardDesc}
                        toolbarClassName="card-desc-toolbar"
                        wrapperClassName="card-desc-wrapper"
                        editorClassName="card-desc-editor"
                        onEditorStateChange={setSelectedCardDesc}
                      />
                    ) : (
                      <div className="description-text" dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(selectedCardDesc.getCurrentContent())) || 'No description provided.' }} />
                    )}
                  </Form.Group>
                  <Row>
                    <Col md={12} sm={16}>
                      <Form onSubmit={handleAttachmentSubmit}>
                        <Form.Group controlId="formFile" className="mb-3">

                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="bold-label">Attachments:</Form.Label>
                            <div className="d-flex justify-content-between align-items-center">
                              {files.length > 0 && (
                                <Button variant="primary" className="edit-button me-3" type="submit">Upload</Button>
                              )}
                              <Button
                                className="edit-button"
                                onClick={() => setIsEditFileMode(!isEditFileMode)}
                              >
                                {isEditFileMode ? 'Close' : 'Edit'}
                              </Button>
                            </div>
                          </div>

                          {isEditFileMode && (
                            <Form.Control type="file" multiple onChange={handleFileChange} />
                          )}
                        </Form.Group>
                        {renderFilePreviews()}
                      </Form>

                      {selectedImage && (
                        <div className="fullscreen-overlay" onClick={() => setSelectedImage(null)}>
                          <button className="fullscreen-close-button" onClick={() => setSelectedImage(null)}>
                            <i className='bx bx-x'></i>
                          </button>
                          <button className="fullscreen-download-button" onClick={() => {
                            const link = document.createElement('a');
                            link.href = selectedImage;
                            link.download = 'DownloadedImage';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}>
                            <i className='bx bx-download'></i>
                          </button>
                          <img src={selectedImage} alt="Full Screen" className="preview-image-fullscreen" onClick={(e) => e.stopPropagation()} />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey="todo_list" className="nav-tab-container">
                    <Tab eventKey="todo_list" title="To do">
                      <Form.Group className="mb-3">
                        <Form.Label className="bold-label mt-15">To do:</Form.Label>
                        <ListGroup>
                          {selectedCard.todo_list.map((todo, index) => (
                            <div key={index} className="d-flex align-items-center mb-2">
                              <Form.Check
                                type="checkbox"
                                checked={todo.completed}
                                onChange={() => toggleTodoCompletion(index)}
                                className="me-2"
                              />
                              <Form.Control
                                type="text"
                                value={todo.text}
                                placeholder='Add an item'
                                onChange={(e) => handleUpdateTodoItem(index, e.target.value)}
                                disabled={!isEditMode}
                                className="me-2 flex-grow-1"
                              />
                              {isEditMode && (
                                <Button variant="danger" onClick={() => handleRemoveTodoItem(index)}>Remove</Button>
                              )}
                            </div>
                          ))}
                        </ListGroup>
                        {isEditMode && (
                          <Button variant="primary" onClick={handleAddTodoItem} className="edit-button mt-10 me-3">Add</Button>
                        )}
                        <Button variant="secondary" onClick={handleEditButtonClick} className="edit-button mt-10">
                          {isEditMode ? 'Save' : 'Edit'}
                        </Button>
                      </Form.Group>
                    </Tab>
                    <Tab eventKey="checklist" title="Checklist">
                      <Form.Group className="mb-3">
                        <Form.Label className="bold-label mt-15" >Checklist:</Form.Label>

                        <ListGroup>
                          {selectedCard.checklist.map((item, index) => (
                            <ListGroup.Item key={index} className="d-flex align-items-center">
                              <Form.Check
                                type="checkbox"
                                checked={item.checked}
                                onChange={(e) => handleChecklistItemChange(index, e.target.checked, 'checked')}
                                className="me-3"
                              />
                              <Form.Control
                                type="text"
                                value={item.text}
                                placeholder='Add an item'
                                onChange={(e) => handleChecklistItemChange(index, e.target.value, 'text')}
                                disabled={!isEditMode}
                                className="me-3 flex-grow-1"
                              />
                              {isEditMode && (
                                <Button variant="danger" onClick={() => handleRemoveChecklistItem(index)}>Remove</Button>
                              )}

                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        {isEditMode && (
                          <Button variant="secondary" onClick={handleAddChecklistItem} className="edit-button mt-10 me-3">Add</Button>
                        )}
                        <Button variant="secondary" onClick={handleEditButtonClick} className="edit-button mt-10">
                          {isEditMode ? 'Save' : 'Edit'}
                        </Button>
                      </Form.Group>
                    </Tab>
                  </Tabs>

                  <hr></hr>
                  <Col>
                    <Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <Form.Label className="bold-label">Comments</Form.Label>
                      </div>
                      {selectedCard.comments && selectedCard.comments.map((comment) => (
                        <Row key={comment.id} className="comment-row">
                          <Col className="col-auto mb-0">
                            <div className="user-icon">
                              {
                                (comment.user.full_name ?
                                  (comment.user.full_name.length >= 2 ? comment.user.full_name.slice(0, 2) : comment.user.full_name[0])
                                  :
                                  (comment.user.username.length >= 2 ? comment.user.username.slice(0, 2) : comment.user.username[0])
                                ).toUpperCase()
                              }
                            </div>
                          </Col>
                          <div className="col comment-text">
                            {comment.message}
                          </div>
                          <Col xs="auto" className="mb-0">
                            <div className="d-flex justify-content-end">
                              <Button variant="link" onClick={() => setReplyingToCommentId(comment.id)}>Reply</Button>
                              {comment.user.id === user.id && (
                                <Button variant="link" onClick={() => handleDeleteComment(comment.id)} className="ml-2">Delete</Button>
                              )}
                            </div>
                          </Col>
                          <div className="reply-row">
                            {Array.isArray(comment.replies) && comment.replies.slice(0, expandedReplies[comment.id] ? comment.replies.length : 3).map((reply) => (
                              <Row className="mb-2 align-items-center pl-4">

                                <Col className="col-auto mb-0">
                                  <div className="user-icon user-icon-reply">
                                    {
                                      (reply.user.full_name ?
                                        (reply.user.full_name.length >= 2 ? reply.user.full_name.slice(0, 2) : reply.user.full_name[0])
                                        :
                                        (reply.user.username.length >= 2 ? reply.user.username.slice(0, 2) : reply.user.username[0])
                                      ).toUpperCase()
                                    }
                                  </div>
                                </Col>
                                <Col>
                                  <div className="reply-message">
                                    {reply.message}
                                  </div>
                                </Col>
                              </Row>

                            ))}
                            {comment.replies.length > 3 && !expandedReplies[comment.id] && (
                              <div className="see-more" onClick={() => setExpandedReplies({ ...expandedReplies, [comment.id]: true })}>
                                See More
                              </div>
                            )}
                          </div>
                          {replyingToCommentId === comment.id && (
                            <div className="d-flex align-items-center">
                              <div className="user-icon me-2">
                                {user.full_name[0].toUpperCase()}
                              </div>
                              <Form.Control
                                as="textarea"
                                placeholder="Write a reply..."
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                                className="flex-grow-1 me-2"
                              />
                              <Button onClick={() => handleAddReply(comment.id)}>Send</Button>
                            </div>
                          )}

                        </Row>
                      ))}
                      <Row className="add-comment-row">
                        <Col className="col-auto mb-0">
                          <div className="user-icon">
                            {user.full_name.slice(0, 2).toUpperCase()}
                          </div>
                        </Col>
                        <div className="col">
                          <Form.Control
                            as="textarea"
                            placeholder="Write a comment..."
                            value={newComment}
                            onChange={e => setNewComment(e.target.value)}
                            onKeyPress={e => e.key === 'Enter' && handleAddComment()}
                            style={{ padding: '10px', height: 'auto' }}
                          />
                        </div>
                      </Row>
                      {newComment.trim().length > 0 && (
                        <div className="comment-button">
                          <Button className="mt-5" onClick={handleAddComment}>Add Comment</Button>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Col>


                <Col md={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="label">Due:</Form.Label>
                    <Form.Control type="date"
                      name='due_date'
                      value={selectedCard.due_date}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 mt-10">
                    <Form.Label className="label">Priority</Form.Label>
                    <Form.Control as="select"
                      name="priority"
                      value={selectedCard.priority}
                      onChange={(e) => handleStatusChange(e, selectedCard.id)}
                    >

                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3 mt-10">
                    <Form.Label className="label">Tags:</Form.Label>
                    <Form.Control
                      type='text'
                      name="tags"
                      value={selectedCard.tags}
                      onChange={(e) => handleUpdateTags(e, selectedCard.id)}>

                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ) : (
            <p>Select a card to see its details.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} centered dialogClassName="w-80">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='card-body'>
              <Modal.Title className="mb-20">Create New Card</Modal.Title>
              <Row>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="bold-label">Name</Form.Label>
                    <Form.Control type="text"
                      name="name"
                      value={cardData.name} onChange={handleChange} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col md={5} sm={6} className="mb-3">
                      <Form.Group>
                        <Form.Label className="label">Collaborator:</Form.Label>
                        <Select
                          placeholder="Select collaborators"
                          options={modifiedCollaborators}
                          isMulti={true}
                          name="collaborator"
                          className="pr-select-container pr-select-container--avatar"
                          classNamePrefix="pr-select"
                          onChange={handleChangeCollaborator}
                        />
                      </Form.Group>

                    </Col>

                    <Col md={5} sm={6} className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label className="label">Category</Form.Label>
                        <Form.Control as="select"
                          name="category"
                          value={cardData.category} onChange={handleChange}>
                          <option value="">Select Category</option>
                          <option value="todo">To do</option>
                          <option value="inProgress">In Progress</option>
                          <option value="inReview">In Review</option>
                          <option value="approved">Approved</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="bold-label">Description</Form.Label>
                        <Editor
                          editorState={newCardDesc}
                          toolbarClassName="card-desc-toolbar"
                          wrapperClassName="card-desc-wrapper"
                          editorClassName="card-desc-editor"
                          onEditorStateChange={handleNewCardDesc}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Tabs defaultActiveKey="todo_list" className="nav-tab-container mb-3 mt-10">
                    <Tab eventKey="todo_list" title="Todo List">
                      <Form.Group className="mb-3">
                        <Form.Label className="bold-label mt-15">To do:</Form.Label>
                        <ListGroup>
                          {cardData.todo_list.map((todo, index) => (
                            <div key={index} className="d-flex align-items-center mb-2">
                              <Form.Check
                                type="checkbox"
                                checked={todo.completed}
                                onChange={() => toggleTodoCompletion(index)}
                                className="me-2"
                              />
                              <Form.Control
                                type="text"
                                value={todo.text}
                                onChange={(e) => handleUpdateTodoItem(index, e.target.value)}
                                className="me-2 flex-grow-1"
                              />
                              <Button variant="danger" onClick={() => handleRemoveTodoItem(index)}>Remove</Button>
                            </div>
                          ))}
                        </ListGroup>
                        <Button variant="primary" onClick={handleAddTodoItem} className="edit-button mt-10 me-3">Add</Button>
                      </Form.Group>
                    </Tab>
                    <Tab eventKey="checklist" title="Checklist">
                      <Form.Group className="mb-3">
                        <Form.Label className="bold-label mt-15">Checklist:</Form.Label>

                        <ListGroup>
                          {cardData.checklist.map((item, index) => (
                            <ListGroup.Item key={index} className="d-flex align-items-center">
                              <Form.Check
                                type="checkbox"
                                checked={item.checked}
                                onChange={(e) => handleChecklistItemChange(index, e.target.checked, 'checked')}
                                className="me-3"
                              />
                              <Form.Control
                                type="text"
                                value={item.text}
                                onChange={(e) => handleChecklistItemChange(index, e.target.value, 'text')}
                                className="me-3 flex-grow-1"
                              />
                              <Button variant="danger" onClick={() => handleRemoveChecklistItem(index)}>Remove</Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <Button variant="secondary" onClick={handleAddChecklistItem} className="edit-button mt-10 me-3">Add</Button>
                      </Form.Group>
                    </Tab>
                  </Tabs>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="label">Due:</Form.Label>
                    <Form.Control type="date"
                      name='due_date'
                      value={cardData.due_date}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 mt-10">
                    <Form.Label className="label">Priority</Form.Label>
                    <Form.Control as="select"
                      name="priority"
                      value={cardData.priority}
                      onChange={handleChange} >
                      <option value="">Select Status</option>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3 mt-10">
                    <Form.Label className="label">Tags:</Form.Label>
                    <Form.Control type='text'
                      name="tags"
                      value={cardData.tags}
                      onChange={handleChange}>

                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {/* <Form.Group className="mb-3">
              <Form.Label>Invoice</Form.Label>
              <Form.Select name="invoice" value={cardData.invoice} onChange={handleChange}>
                <option value="">Select Invoice</option>
                {invoice.map((invoice) => (
                  <option key={invoice.id} value={invoice.id}>
                    {invoice.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Create Card</Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default Card;
