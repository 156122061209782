import React from "react";

function UserProfileBox() {

    return (
              <div className="row">
                <div className="col-12">
                    <div className="box card-box mb-20">
                        <div className="icon-box bg-color-1">
                            <div className="icon bg-icon-1">
                                <i className='bx bxs-briefcase'></i>
                            </div>
                            <div className="content color-1">
                                <h5 className="title-box fs-17 font-w400 mb-1">Total Task</h5>
                                <div className="themesflat-counter fs-18 font-wb">
                                    <span className="number" data-from="0" data-to="1225" data-speed="2500" data-inviewport="yes">1225</span>
                                </div>
                            </div>
                        </div>
                        <div className="icon-box bg-color-2">
                            <div className="icon bg-icon-2">
                                <i className='bx bx-task'></i>
                            </div>
                            <div className="content click-c color-2">
                                <h5 className="title-box fs-17 font-w400 mb-1">Running Task</h5>
                                <div className="themesflat-counter fs-18 font-wb">
                                    <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">154 +</span>
                                </div>
                            </div>

                        </div>
                        <div className="icon-box bg-color-3">
                            <div className="icon bg-icon-3">
                                <i className='bx bx-block'></i>
                            </div>
                            <div className="content click-c color-3">
                                <h5 className="title-box fs-17 font-w400 mb-1">On Hold Task</h5>
                                <div className="themesflat-counter fs-18 font-wb">
                                    <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">75 +</span>
                                </div>
                            </div>

                        </div>
                        <div className="icon-box bg-color-5">
                            <div className="icon bg-icon-5">
                                <i className='bx bx-task'></i>
                            </div>
                            <div className="content click-c color-4">
                                <h5 className="title-box fs-17 font-w400 mb-1">Complete Task</h5>
                                <div className="themesflat-counter fs-18 font-wb">
                                    <span className="number" data-from="0" data-to="309" data-speed="2500" data-inviewport="yes">120 +</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    )
}

export default UserProfileBox;