import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import DataServices from '../api/services';
import logo from '../images/pinkrobot.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


function NewAccount() {
  const [showPassword, setShowPassword] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsError, setTermsError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [accountCreated, setAccountCreated] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    staff: {
      phone_no: '',
      address: '',
      ip_address: '',
      role: 'Staff' // Assuming 'null' is the default value
    }
  });
  const [errors, setErrors] = useState({});
  const [formVisible, setFormVisible] = useState(true);

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    // Email validation
    if (!formData.email) {
      formIsValid = false;
      errors["email"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      errors["email"] = "Email is invalid.";
    }

    // Username validation
    if (!formData.username) {
      formIsValid = false;
      errors["username"] = "Username is required.";
    } else if (formData.username.length < 4) {
      formIsValid = false;
      errors["username"] = "Username must be at least 4 characters long.";
    }

    // Password validation
    if (!formData.password) {
      formIsValid = false;
      errors["password"] = "Password is required.";
    } else if (formData.password.length < 6) {
      formIsValid = false;
      errors["password"] = "Password must be at least 6 characters long.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleCheckboxChange = () => {
    setTermsAgreed(!termsAgreed);
    if (!termsAgreed) {
      setTermsError("");
    }
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const [key, nestedKey] = name.split('.');
      setFormData(prevFormData => ({
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          [nestedKey]: value
        }
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!termsAgreed) {
      setTermsError("You must agree to the terms of services and privacy policy.");
      return;
    }

    try {
      const emailCheckResult = await DataServices.checkEmailExist(formData.email);
      if (emailCheckResult.email_exists === true) {
        setAccountCreated(false);
        setMessage('Account already registered. Please log in or use the forgot password feature if necessary.');
        return;
      }

      await DataServices.createUser(formData);
      setFormVisible(false);
      setAccountCreated(true);
      setMessage('Account created successfully. Please check your email to activate your account.');
    } catch (error) {
      setAccountCreated(false);
      setMessage('Failed to create account. Please try again.');
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={10} xl={8}>
          <section className="login signup">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header d-flex justify-content-between">
                  <a href="index.html">
                    <img src={logo} alt="Logo" />
                  </a>

                  <div className="action-reg">
                    <h4 className="fs-30">Register</h4>
                    <a href="/new-account">Create new account</a>
                  </div>
                </div>
                <div className="line"></div>
                <div className="box-body">
                  <div className="auth-content my-auto">
                    {message && (
                      <div className={`alert ${accountCreated ? 'alert-success' : 'alert-danger'}`} role="alert">
                        {message}
                      </div>
                    )}
                    {formVisible && (
                      <Form className="mt-6 pt-2" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="username">
                          <Form.Label>User Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Your Name"
                            name="username" // Make sure to include the `name` attribute
                            value={formData.username}
                            onChange={handleInputChange}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mt-24" controlId="useremail">
                          <Form.Label>E-Mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Your Email"
                            name="email" // Make sure to include the `name` attribute
                            value={formData.email}
                            onChange={handleInputChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mt-24">
                          <Form.Label>Password</Form.Label>
                          <InputGroup className="input-group auth-pass-inputgroup">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter password"
                              aria-describedby="password-addon"
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                              isInvalid={!!errors.password}
                            />

                            <Button variant="link" className="btn shadow-none ms-0" onClick={togglePasswordVisibility} id="password-addon">
                              <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                            </Button>

                          </InputGroup>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Check type="checkbox" id="terms-checkbox">
                            <Form.Check.Input
                              type="checkbox"
                              onChange={handleCheckboxChange}
                              checked={termsAgreed}
                            />
                            <Form.Check.Label>
                              I agree to the <a href="#" className="text-primary">Terms of services</a> &amp; <a href="#" className="text-primary">Privacy policy</a>
                            </Form.Check.Label>
                          </Form.Check>
                          {termsError && <div className="text-danger mt-2">{termsError}</div>} {/* Display the error message here */}
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                          Create Account
                        </Button>
                      </Form>
                    )}


                    {formVisible && (
                      <div className="mt-59 text-center">
                        <p className="text-muted mb-0 fs-14">Already have an account ? <a href="/user-login" className="text-primary fw-semibold"> Sign in </a></p>
                      </div>
                    )}

                    {formVisible && (
                      <div className="mt-37 text-center">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <a href="javascript:void()" className="social-list-item text-white">
                              <i className='bx bxl-facebook-square'></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript:void()" className="social-list-item text-white">
                              <i className='bx bxl-twitter'></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript:void()" className="social-list-item text-white">
                              <i className='bx bxl-linkedin-square'></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript:void()" className="social-list-item text-white">
                              <i className='bx bxl-google-plus'></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>

  );
}

export default NewAccount;
