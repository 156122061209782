import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, FormControl} from 'react-bootstrap';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import ProfileImage from '../images/profile.png';
import UserProfileBox from '../components/UserProfileBox';


function UserProfile() {
    let params = useParams();
    const [formData, setFormData] = useState({
        status: '',
        restriction: null,
    });

    const handleChange = (e) => {
        const { name, value, type, options } = e.target;
    
      if (e.target.multiple) {
            // Handle multiple selects for collaborators
            const selectedValues = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
            setFormData(prevState => ({
                ...prevState,
                [name]: selectedValues
            }));
        } else {
            // Handle other input types here (e.g., text, select single option)
            setFormData(prevState => ({
                ...prevState,
                [name]: type === 'number' ? Number(value) : value
            }));
        }
    };

    return (
        <>
            <Header />
            <div className="main">

                <div className="main-content user">
                    <UserProfileBox />
                    <Row>
                        <Col md={12}>
                            <div className="box">
                                <div className="box-body pb-30">
                                    <Row>
                                        <Col md={12} xl={10} className="mb-0">
                                            <Row>
                                                <Col md={4} sm={12} className="mb-0">
                                                    <Form.Group>
                                                        <Form.Label>From:</Form.Label>
                                                        <InputGroup>
                                                            <InputGroup.Text>
                                                                <i className="bx bx-calendar"></i>
                                                            </InputGroup.Text>
                                                            <FormControl type="date"
                                                                name='project_start'
                                                                value={formData.project_start}
                                                                onChange={handleChange}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} sm={12} className="mb-0">
                                                    <Form.Group>
                                                        <Form.Label>To:</Form.Label>
                                                        <InputGroup>
                                                            <InputGroup.Text>
                                                                <i className="bx bx-calendar"></i>
                                                            </InputGroup.Text>
                                                            <FormControl type="date"
                                                                name='project_end'
                                                                value={formData.project_end}
                                                                onChange={handleChange}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} sm={12} className="mb-0">
                                                    <Form.Group>
                                                        <Form.Label>Select Priority:</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="priority"
                                                            value={formData.priority}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <option value="">Select Priority</option>
                                                            <option value="high">High</option>
                                                            <option value="medium">Medium</option>
                                                            <option value="low">Low</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                        <Col md={12} xl={2} className="mb-0">
                                            <Form.Group className="mt-32">
                                                <a href="#" className="search btn bg-primary btn-block color-white">Search</a>
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                <div className="box-body pb-0 table-responsive activity mt-18">
                                    <table className="table table-vcenter text-nowrap table-bordered dataTable no-footer mw-100" id="task-profile" role="grid">
                                        <thead>
                                            <tr className="top">
                                                <th className="border-bottom-0 text-center sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '26.6562px' }}>No</th>
                                                <th className="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '222.312px' }}>Task</th>
                                                <th className="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '84.8281px' }}>Priority</th>
                                                <th className="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '87.9844px' }}>Start Date</th>
                                                <th className="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '87.9844px' }}>Deadline</th>
                                                <th className="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '71.875px' }}>Progress</th>
                                                <th className="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{ width: '110.719px' }}>Work Status</th>
                                                <th className="border-bottom-0 sorting_disabled fs-14 font-w500" rowspan="1" colspan="1" style={{ width: '145.391px' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">1</td>
                                                <td><a href="#" className="d-flex"><span>Design Updated</span></a></td>
                                                <td><span class="badge badge-danger-light">High</span></td>
                                                <td>12-02-2021</td>
                                                <td>16-06-2021</td>
                                                <td>
                                                    <div class="progress progress-sm">
                                                        <div class="progress-bar bg-primary w-30"></div>
                                                    </div>
                                                </td>
                                                <td><span class="badge badge-warning">On hold</span></td>
                                                <td>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className='bx bx-dots-horizontal-rounded'></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">2</td>
                                                <td><a href="#" className="d-flex"><span>Website Develop</span></a></td>
                                                <td><span class="badge badge-success-light">Low</span></td>
                                                <td>01-01-2021</td>
                                                <td>22-04-2021</td>
                                                <td>
                                                    <div class="progress progress-sm">
                                                        <div class="progress-bar bg-yellow w-50"></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex"> <span class="badge badge-danger">Dealy</span>
                                                        <div class="mt-1 ms-1"> <span class="feather feather-info text-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Dealy by 99 days"></span> </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className='bx bx-dots-horizontal-rounded'></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">3</td>
                                                <td>
                                                    <a href="#" class="d-flex "><span>Digital Marketing</span> </a>
                                                </td>
                                                <td><span class="badge badge-warning-light">Medium</span></td>
                                                <td>11-04-2021</td>
                                                <td>16-06-2021</td>
                                                <td>
                                                    <div class="progress progress-sm">
                                                        <div class="progress-bar bg-primary w-100"></div>
                                                    </div>
                                                </td>
                                                <td> <span class="badge badge-success">Completed</span> </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className='bx bx-dots-horizontal-rounded'></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="bx bx-trash"></i> Delete</a>
                                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="bx bx-edit mr-5"></i>Edit</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="box user-pro-list overflow-hidden mb-30">
                                <div className="box-body">
                                    <div className="user-pic text-center">
                                        <div className="avatar">
                                            <img src={ProfileImage} alt="profile" className="w-100"/>
                                            <span class="pulse-css"></span>
                                        </div>
                                        <div className="pro-user mt-40">
                                            <h5 class="pro-user-username text-dark mb-1 fs-15">Helena Zahangir</h5>
                                            <h6 class="pro-user-desc text-muted fs-14">helena.rzahan@mail.com</h6>
                                            <div class="star-ratings start-ratings-main mb-10 clearfix">
                                                <div class="stars stars-example-fontawesome star-sm">
                                                    <div class="br-wrapper br-theme-fontawesome-stars">
                                                        <div class="br-wrapper br-theme-fontawesome-stars">
                                                            <select id="example-fontawesome" name="rating" style={{display: 'none'}}>
                                                                <option value="1">1</option> 
                                                                <option value="2">2</option> 
                                                                <option value="3">3</option> 
                                                                <option value="4" selected="">4</option> 
                                                                <option value="5">5</option> 
                                                            </select>
                                                            <div class="br-widget">
                                                                <a href="#" data-rating-value="1" data-rating-text="1" class="br-selected"></a>
                                                                <a href="#" data-rating-value="2" data-rating-text="2" class="br-selected"></a>
                                                                <a href="#" data-rating-value="3" data-rating-text="3" class="br-selected"></a>
                                                                <a href="#" data-rating-value="4" data-rating-text="4" class="br-selected br-current"></a>
                                                                <a href="#" data-rating-value="5" data-rating-text="5"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-body pt-19">
                                    <div class="row">
                                        <div class="col-4 text-center mb-0 pd-0">
                                            <div class="icon-box">
                                                <i class="bx bxs-briefcase"></i>
                                            </div>

                                            <h5 class="fs-14 font-w400 mb-0">Total Project</h5>
                                            <h5 class="mb-2"> <span class="fs-14 font-w600 ">55 +</span> </h5>
                                        </div>
                                        <div class="col-4  text-center mb-0 pd-0">
                                            <div class="icon-box">
                                                <i class="bx bxs-shopping-bags"></i>
                                            </div>

                                            <h5 class="fs-14 font-w400 mb-0">Total Task</h5>
                                            <h5 class="mb-2"> <span class="fs-14 font-w600">35 +</span> </h5>
                                        </div>
                                        <div class="col-4 text-center mb-0 pd-0">
                                            <div class="icon-box">
                                                <i class="bx bxs-trophy"></i>
                                            </div>

                                            <h5 class="fs-14 font-w400 mb-0">Awards</h5>
                                            <h5 class="mb-2"> <span class="fs-14 font-w600 ">03</span> </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer pt-38">
                                    <div class="btn-list text-center">
                                        <a href="#" class="btn btn-light"><i class="bx bxs-envelope"></i> </a>
                                        <a href="#" class="btn btn-light"> <i class="bx bxs-phone-call"></i> </a>
                                        <a href="#" class="btn btn-light"><i class="bx bxs-error-circle"></i></a>
                                        <a href="#" class="btn btn-light"> <i class="bx bxs-message-alt-edit"></i> </a>
                                    </div>
                                </div>
                            </div>
                            <div class="box left-dot pt-39 mb-30">
                                <div class="box-header  border-0 ">
                                    <div class="box-title fs-20 font-w600">Basic Info</div>
                                </div>
                                <div class="box-body pt-16 user-profile">
                                    <div class="table-responsive">
                                        <table class="table mb-0 mw-100 color-span">
                                            <tbody>
                                                <tr>
                                                    <td class="py-2 px-0"> <span class="w-50">Employee ID</span> </td>
                                                    <td>:</td>
                                                    <td class="py-2 px-0"> <span class="">#365900</span> </td>
                                                </tr>
                                                <tr>
                                                    <td class="py-2 px-0"> <span class="w-50">Designation</span> </td>
                                                    <td>:</td>
                                                    <td class="py-2 px-0"> <span class="">Sr. Designer</span> </td>
                                                </tr>
                                                <tr>
                                                    <td class="py-2 px-0"> <span class="w-50">Department</span> </td>
                                                    <td>:</td>
                                                    <td class="py-2 px-0"> <span class="">Development</span> </td>
                                                </tr>
                                                <tr>
                                                    <td class="py-2 px-0"> <span class="w-50">Join Date</span> </td>
                                                    <td>:</td>
                                                    <td class="py-2 px-0"> <span class="">25 - 12 - 2022</span> </td>
                                                </tr>
                                                <tr>
                                                    <td class="py-2 px-0"> <span class="w-50">Phone Number</span> </td>
                                                    <td>:</td>
                                                    <td class="py-2 px-0"> <span class="">+11 05986 2359 03</span> </td>
                                                </tr>
                                                <tr>
                                                    <td class="py-2 px-0"> <span class="w-50">Status</span> </td>
                                                    <td>:</td>
                                                    <td class="py-2 px-0"> <span class="badge badge-success">Active</span> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="box left-dot">
                                <div class="box-header  border-0 ">
                                    <div class="box-title fs-20 font-w600">Statistics</div>
                                </div>
                                <div class="box-body pt-10 ">
                                    <div class="d-flex justify-content-between mt-10">
                                        <div class="progress-cicle">
                                            <div class="chart-circle chart-circle-xl" data-value="0.75" data-thickness="5" data-color="#E6154E"><canvas width="140" height="140" style={{height: '70px;', width: '70px;'}}></canvas><canvas width="80" height="80"></canvas><canvas width="80" height="80"></canvas>
                                                <div class="chart-circle-value">45%</div>
                                            </div>
                                            <div class="title-progress fs-15 font-w600 mt-10">Task</div>
                                        </div>
                                        <div class="progress-cicle">
                                            <div class="chart-circle chart-circle-xl" data-value="0.75" data-thickness="5" data-color="#3C21F7"><canvas width="140" height="140" style={{height: '70px;', width: '70px;'}}></canvas><canvas width="80" height="80"></canvas><canvas width="80" height="80"></canvas>
                                                <div class="chart-circle-value">55%</div>
                                            </div>
                                            <div class="title-progress fs-15 font-w600 mt-10">Projects</div>
                                        </div>
                                        <div class="progress-cicle">
                                            <div class="chart-circle chart-circle-xl" data-value="0.75" data-thickness="5" data-color="#E58911"><canvas width="140" height="140" style={{height: '70px;', width: '70px;'}}></canvas><canvas width="80" height="80"></canvas><canvas width="80" height="80"></canvas>
                                                <div class="chart-circle-value">75%</div>
                                            </div>
                                            <div class="title-progress fs-15 font-w600 mt-10">Performanance</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default UserProfile;