import React, { useState, useRef, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import DataServices from '../api/services';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';

function AddClient() {
    const [showAddClientPopup, setShowAddClientPopup] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    let params = useParams();
    const [formData, setFormData] = useState({
        workspace: '',
        user: null,
        type: null,
        name: null,

    });
    const [workspaces, setWorkspaces] = useState([]);
    const [userAccounts, setUsers] = useState([]);
    // const [type, setType] = useState([])
    const { authTokens, user } = useAuth();

    useEffect(() => {
        const fetchWorkspaces = async () => {
            try {
                const data = await DataServices.getWorkspaces(authTokens);

                if (Array.isArray(data.results)) {
                    setWorkspaces(data.results);
                } else {
                    console.error('Expected an array of workspaces, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching workspaces:', error);
            }
        };

        fetchWorkspaces();
    }, []);
    useEffect(() => {
        const fetchWorkspaces = async () => {
            try {
                const data = await DataServices.getUsers(authTokens);

                if (Array.isArray(data.results)) {
                    // console.log(data.results)
                    let getAllUsers = [];
                    data.results.map((col) => {
                        if(col.user_details && col.first_name != '' && col.last_name != '') { //don't show users with no user_details and name
                            const url = col.url;
                            // console.log("URL:", url);
                            const match = url.match(/\/users\/(\d+)/);
                            // console.log("Match result:", match);
                            getAllUsers.push({'value': match[1], 'label': col.username});
                        }
                    });
                    console.log(getAllUsers)
                    setUsers(getAllUsers);
                } else {
                    console.error('Expected an array of workspaces, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching workspaces:', error);
            }
        };

        fetchWorkspaces();
    }, []);
    
    const handleChange = (e) => {
        const { name, value, type, options } = e.target;
    
        if (type === "radio") {
            // Handle radio buttons
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (e.target.multiple) {
            // Handle multiple selects for collaborators
            const selectedValues = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
            setFormData(prevState => ({
                ...prevState,
                [name]: selectedValues
            }));
        } else {
            // Handle other input types here (e.g., text, select single option)
            setFormData(prevState => ({
                ...prevState,
                [name]: type === 'number' ? Number(value) : value
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let submitData = { ...formData };

        const data = new FormData();
        Object.keys(submitData).forEach(key => {
            data.append(key, submitData[key]);
        });

        setShowAddClientPopup(false);
        try {
            const clientResponse = await DataServices.createClients(formData, authTokens);

            setIsSuccessPopupVisible(true);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } catch (error) {
            setIsErrorPopupVisible(true);
            console.error('Error fetching workspaces:', error);
        }
    };

    const closePopup = () => {
        setIsSuccessPopupVisible(false);
        setIsErrorPopupVisible(false);
    };
    const handleAddClient = (event) => {
        setShowAddClientPopup(true);
    }
    const closeAddClientPopup = (event) => {
        setShowAddClientPopup(false);
    }

    return (
        <div>
            <a className="add d-flex bg-primary justify-content-center" onClick={handleAddClient}>
                Add Client <i class="fas fa-plus-circle"></i>
            </a>
            {showAddClientPopup &&
                <Modal 
                    show={showAddClientPopup}
                    onHide={closeAddClientPopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal">
                    <Modal.Header>
                        <Modal.Title>Add Client</Modal.Title>
                        <Button className="close" onClick={closeAddClientPopup}><span aria-hidden="true">&times;</span></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div className="box">
                                    <div className="box-body">
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                
                                                <Col md={6} sm={12} className="mb-24">
                                                    <Form.Group>
                                                        <Form.Label>User account:</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="user"
                                                            value={formData.user} // Changed from formData.client.id to formData.client
                                                            onChange={handleChange}
                                                            required>
                                                            <option value="">Select user account</option>
                                                            {userAccounts.map(userAccounts => (
                                                                <option key={userAccounts.value} value={userAccounts.value}>
                                                                    {userAccounts.label}
                                                                </option>
                                                            ))}
                                                        </Form.Control>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="mb-24">
                                                    <Form.Group>
                                                        <Form.Label>Type:</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="type"
                                                            value={formData.type} // Changed from formData.client.id to formData.client
                                                            onChange={handleChange}
                                                            required>
                                                            <option value="">Select client type</option>
                                                            
                                                            <option value="lead">Lead</option>
                                                            <option value="proposal sent">Proposal Sent</option>
                                                            <option value="contract signed">Contract Signed</option>
                                                            <option value="archived">Archived</option>
                                                            <option value="completed">Completed</option>
                                                        </Form.Control>

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} sm={12} className="mb-24">
                                                    <Form.Group>
                                                        <Form.Label>Workspace:</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="workspace"
                                                            value={formData.workspace}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Workspace</option>
                                                            {workspaces.map(workspace => (
                                                                <option key={workspace.id} value={workspace.id}>
                                                                    {workspace.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className="gr-btn mt-15">
                                                <Button variant="primary" type="submit" className="btn-lg fs-16">SUBMIT</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* {message && (
                            <div className={`alert ${clientCreated ? 'alert-success' : 'alert-danger'}`} role="alert">
                            {message}
                            </div>
                        )} */}
                    </Modal.Body>
                </Modal>
            }
            {isSuccessPopupVisible && (
                 <Modal 
                    show={isSuccessPopupVisible}
                    onHide={closePopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal">
                    <Modal.Header>
                        <Button className="close" onClick={closePopup}><span aria-hidden="true">&times;</span></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p class="text-black">Client added successfully.</p>
                    </Modal.Body>
                </Modal>
            )}
            {isErrorPopupVisible && (
                 <Modal 
                    show={setIsErrorPopupVisible}
                    onHide={closePopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal">
                    <Modal.Header>
                        <Button className="close" onClick={closePopup}><span aria-hidden="true">&times;</span></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p class="text-black">There are errors on the form data. Check browser console for more information.</p>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    )
}

export default AddClient;