
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';

import NotificationBox from "../components/NotificationBox";
import { Row, Col } from "react-bootstrap";

function Calendar() {
    return(
        <div className="main">
            <div className="main-content message">
                <NotificationBox></NotificationBox>
                <Row>
                    <Col xs={12}>
                        <FullCalendar
                            plugins={[ dayGridPlugin, timeGridPlugin, listPlugin ]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                left: "prev next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth"
                            }}
                            navLinks={true}
                            businessHour={true}
                            editabler={true}
                            selectabler={true}
                            events={[{
                                    title: 'Business Lunch',
                                    start: '2024-02-03T13:00:00',
                                    constraint: 'businessHours'
                                },
                                {
                                    title: 'Meeting',
                                    start: '2024-02-13T11:00:00',
                                    constraint: 'availableForMeeting', // defined below
                                    color: '#257e4a'
                                },
                                {
                                    title: 'Conference',
                                    start: '2024-02-18',
                                    end: '2024-02-20'
                                },
                                {
                                    title: 'Party',
                                    start: '2024-02-29T20:00:00'
                                },

                                // areas where "Meeting" must be dropped
                                {
                                    groupId: 'availableForMeeting',
                                    start: '2024-02-11T10:00:00',
                                    end: '2024-02-11T16:00:00',
                                    display: 'background'
                                },
                                {
                                    groupId: 'availableForMeeting',
                                    start: '2024-02-13T10:00:00',
                                    end: '2024-02-13T16:00:00',
                                    display: 'background'
                                },

                                // red areas where no events can be dropped
                                {
                                    start: '2024-02-24',
                                    end: '2024-02-28',
                                    overlap: false,
                                    display: 'background',
                                    color: '#ff9f89'
                                },
                                {
                                    start: '2024-02-06',
                                    end: '2024-02-08',
                                    overlap: false,
                                    display: 'background',
                                    color: '#ff9f89'
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Calendar;