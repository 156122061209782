import React, { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import DataServices from '../api/services';
import { useAuth } from '../context/AuthContext';

function CreateProject(props) {
    const {isPopup, isPage, setShowCreateProjectPopup, handleAdditionalAction} = props;
    const [formData, setFormData] = useState({
        name: '',
        status: '',
        restriction: null,
        progress: '0',
        project_start: '',
        project_end: '',
        description: '',
        workspace: '',
        collaborators: [],
        client: '',

    });
    const [workspaces, setWorkspaces] = useState([]);
    const [collaborator, setCollaborator] = useState([]);
    const [selectedCollab, setSelectedCollab] = useState([]);
    const [client, setClient] = useState([])
    const [fileData, setFileData] = useState(null);
    const { authTokens, user } = useAuth();
    const [isSuccess, setIsSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const fetchWorkspaces = async () => {
            try {
                const data = await DataServices.getWorkspaces(authTokens);

                if (Array.isArray(data.results)) {
                    setWorkspaces(data.results);
                } else {
                    console.error('Expected an array of workspaces, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching workspaces:', error);
            }
        };
        const fetchCollaborators = async () => {
            try {
                const data = await DataServices.getCollaborators(authTokens);

                if (Array.isArray(data.results)) {
                    let getAllCollaborators = [];
                    data.results.map((col) => {
                        getAllCollaborators.push({'value': col.id, 'label': col.name});
                    });
                    setCollaborator(getAllCollaborators);
                } else {
                    console.error('Expected an array of collaborators, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching collaborators:', error);
            }
        };
        const fetchClients = async () => {
            try {
                const data = await DataServices.getClients(authTokens);
                if (Array.isArray(data.results)) {
                    setClient(data.results);
                } else {
                    console.error('Expected an list of clients, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchWorkspaces();
        fetchCollaborators();
        fetchClients();
    }, []);

    const scrollToDiv = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleChange = (e) => {
        const { name, value, type, options } = e.target;
    
        if (type === "radio") {
            // Handle radio buttons
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (e.target.multiple) {
            // Handle multiple selects for collaborators
            const selectedValues = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
            setFormData(prevState => ({
                ...prevState,
                [name]: selectedValues
            }));
        } else {
            // Handle other input types here (e.g., text, select single option)
            setFormData(prevState => ({
                ...prevState,
                [name]: type === 'number' ? Number(value) : value
            }));
        }
    };
    
    const validateField = (e) => {
        const { name } = e.target
        const value = formData[name]
        let message = '';

        if (!value) {
            message = 'This field is required';
        }

        setErrors({ ...errors, [name]: message })
    }

    const validateForm = () => {
        const { name, status, restriction, project_start, description, workspace, client, collaborators } = formData;
        let isValid = true
        let errors = {};
        if (!name) {
            errors['name'] = 'This field is required';
            isValid = false
        }

        if (!status) {
            errors['status'] = 'This field is required';
            isValid = false
        }

        if (!restriction) {
            errors['restriction'] = 'This field is required';
            isValid = false
        }

        if (!project_start) {
            errors['project_start'] = 'This field is required';
            isValid = false
        }

        if (!description) {
            errors['description'] = 'This field is required';
            isValid = false
        }

        if (!workspace) {
            errors['workspace'] = 'This field is required';
            isValid = false
        }

        if (!client) {
            errors['client'] = 'This field is required';
            isValid = false
        }

        if (!collaborators.length > 0) {
            errors['collaborators'] = 'This field is required';
            isValid = false
        }

        if (!isValid) {
            setErrors(errors);
            scrollToDiv();
        }
        return isValid;
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            setFileData(file);
        }
    };

    const handleChangeCollab = (event) => {
        setSelectedCollab(event);
        let newCollab = [];
        event.forEach(val => {
            newCollab.push(val.value);
        });
        setFormData(prevState => ({
            ...prevState,
            collaborators: newCollab,
        }));
    };

    const handleChangeCollabBlur = (event) => {
        if(!formData.collaborators.length > 0) {
            setErrors({ ...errors, collaborators: 'This field is required' });
           
        }else {
            setErrors({ ...errors, collaborators: null });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            let submitData = { ...formData };

            const data = new FormData();
            Object.keys(submitData).forEach(key => {
                data.append(key, submitData[key]);
            });

            try {
                const projectResponse = await DataServices.createProject(formData, authTokens);
                if (fileData && projectResponse.id) {
                    const fileFormData = new FormData();
                    fileFormData.append('file', fileData);
                    fileFormData.append('project', projectResponse.id);

                    const fileResponse = await DataServices.attachFileProject(fileFormData);
                    console.log(fileResponse)
                    if (fileResponse.ok) {
                        console.log("File attached successfully");

                    } else {
                        console.error("Failed to attach file");

                    }
                }
                setFormData({
                    name: '',
                    status: '',
                    restriction: null,
                    progress: '0',
                    project_start: '',
                    project_end: '',
                    description: '',
                    workspace: '',
                    collaborators: [],
                    client: '',
            
                });
                setSelectedCollab([]);
                setIsSuccess(true);
                if(isPage) {
                    scrollToDiv();
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 2000);
                }
                if(isPopup) {
                    setTimeout(() => {
                        setIsSuccess(false);
                        setShowCreateProjectPopup(false);
                    }, 3000);
                }
                handleAdditionalAction();
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }
    };

    return(
        <>
        {isSuccess && isPage &&
            <div className="alert alert-success">Your new project has been successfully created.</div>
        }
        <div className={`${isPopup ? 'p-0 ' : ''}box`}>
            <div className="box-body">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>Project Title</Form.Label>
                                <Form.Control placeholder="Software Development"
                                    name='name'
                                    value={formData.name || ''}
                                    onChange={handleChange}
                                    onBlur={validateField}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>Client:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="client"
                                    value={formData.client || ''} // Changed from formData.client.id to formData.client
                                    onChange={handleChange}
                                    onBlur={validateField}
                                    isInvalid={!!errors.client}
                                    >
                                        <option value="">Select client</option>
                                        {client.map(client => (
                                            <option key={client.id} value={client.id}>
                                                {client.name}
                                            </option>
                                        ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.client}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>Collaborator:</Form.Label>
                                <Select
                                    value={selectedCollab}
                                    onChange={handleChangeCollab}
                                    placeholder="Select collaborators"
                                    options={collaborator}
                                    isMulti={true}
                                    name="collaborators"
                                    className={`pr-select-container ${errors.collaborators ? 'is-invalid' : ''}`}
                                    classNamePrefix="pr-select"
                                    onBlur={handleChangeCollabBlur}
                                />
                                {errors.collaborators && 
                                    <Form.Control.Feedback type="invalid">
                                        {errors.collaborators}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="restriction"
                                    value={formData.restriction  || ''}
                                    onChange={handleChange}
                                    onBlur={validateField}
                                    isInvalid={!!errors.restriction}
                                >
                                    <option value="">Select Status</option>
                                    <option value="private">Private</option>
                                    <option value="invite">Invite Users</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.restriction}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>Workspace</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="workspace"
                                    value={formData.workspace || ''}
                                    onChange={handleChange}
                                    onBlur={validateField}
                                    isInvalid={!!errors.workspace}
                                >
                                    <option value="">Select Workspace</option>
                                    {workspaces.map(workspace => (
                                        <option key={workspace.id} value={workspace.id}>
                                            {workspace.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.workspace}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={3} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>From:</Form.Label>
                                <Form.Control type="date"
                                    name='project_start'
                                    value={formData.project_start || ''}
                                    onChange={handleChange}
                                    onBlur={validateField}
                                    isInvalid={!!errors.project_start}
                                />
                                {/* <InputGroup>
                                    <InputGroup.Text>
                                        <i className="bx bx-calendar"></i>
                                    </InputGroup.Text>
                                    
                                </InputGroup> */}
                                <Form.Control.Feedback type="invalid">
                                    {errors.project_start}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={3} sm={12} className="mb-24">
                            <Form.Group>
                                <Form.Label>To:</Form.Label>
                                <Form.Control type="date"
                                    name='project_end'
                                    value={formData.project_end || ''}
                                    onChange={handleChange}
                                />
                                {/* <InputGroup>
                                    <InputGroup.Text>
                                        <i className="bx bx-calendar"></i>
                                    </InputGroup.Text>
                                    
                                </InputGroup> */}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-24">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            name='description'
                            value={formData.description || ''}
                            onChange={handleChange}
                            onBlur={validateField}
                            isInvalid={!!errors.description}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Col md={6} sm={12} className={isPopup ? 'mb-24' : ''}>
                            <Form.Group>
                                <Form.Label>Attachment:</Form.Label>
                                <InputGroup className="file-browser">
                                    <Form.Control
                                        className="border-end-0 browse-file"
                                        placeholder={fileData ? fileData.name : "choose"} // Use fileData.name if a file is selected
                                        readOnly
                                    />
                                    <Button variant="" as="label" className="mb-0 h-auto ripple btn-light">
                                        Browse <Form.Control
                                            type="file"
                                            className="file-browserinput"
                                            name="attachment"
                                            hidden
                                            multiple={false} // Assuming only one file is needed
                                            onChange={handleFileChange}
                                        />
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} className={isPopup ? 'mb-24' : ''}>
                            <div className="d-lg-flex flex-wrap align-items-center">
                                <Form.Label className={`${isPopup ? '' : 'pb-1'} form-label mt-1 fs-18 font-w500 color-primary w-100`}>Work Status:</Form.Label>
                                {['completed', 'pending', 'onProgress'].map((status) => (
                                    <Form.Check
                                        key={status}
                                        inline
                                        label={status.charAt(0).toUpperCase() + status.slice(1)} // Capitalize the first letter
                                        name="status"
                                        type="radio"
                                        id={status}
                                        value={status}
                                        checked={formData.status === status}
                                        onChange={handleChange}
                                        onBlur={validateField}
                                        isInvalid={!!errors.status}
                                        className="mt-0"
                                    />
                                ))}
                                <Form.Control.Feedback type="invalid" className="w-100">
                                    {errors.status}
                                </Form.Control.Feedback>
                            </div>
                        </Col>
                    </Row>
                    <div className="gr-btn">
                        <Button variant="primary" type="submit" className="btn-lg fs-16">SUBMIT</Button>
                    </div>
                </Form>
            </div>
        </div>
        {isSuccess && isPopup &&
            <div className="alert alert-success mt-3">Your new project has been successfully created.</div>
        }
        </>
    )
}

export default CreateProject;