import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'
import { Container, Row, Col, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Logo from '../images/pinkrobot-dark.png';
import { useAuth } from "../context/AuthContext";


function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { login, } = useAuth();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async event => {
        event.preventDefault()
        setErrorMessage("")
        try {
            const resp = await login(email, password)
            if (resp) {
            } else {
                setErrorMessage("Invalid login credentials");
            }
        } catch (error) {
            console.error(error)
            // TODO: actually parse api 400 error messages
            setErrorMessage(error.message)
        }
    }



    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg={10} xl={8}>
                    <section className="login">
                        <div className="row">
                            <div className="col-12">
                                <div className="box">
                                    <div className="box-header d-flex justify-content-between">
                                        <Link to="/">
                                            <img src={Logo} alt="logo" width="300" />
                                        </Link>

                                        <div className="action-reg">
                                            <h4 className="fs-30">Login</h4>
                                            <Link to="/user-login">Sign in to your account</Link>
                                        </div>

                                    </div>
                                    <div className="line"></div>
                                    <div className="box-body">
                                        <div className="auth-content my-auto">
                                            <Form onSubmit={handleSubmit} className="mt-5 pt-2" action="">
                                                <Form.Group className="mb-24">
                                                    <Form.Label className="mb-14">Mail or User Name</Form.Label>
                                                    <Form.Control type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Your text" />
                                                </Form.Group>
                                                <Form.Group className="mb-16">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <Form.Label className="form-label mb-14">Password</Form.Label>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="">
                                                                <Link to="/" className="text-muted">Forgot password?</Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="input-group auth-pass-inputgroup">
                                                        <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" name="password" value={password} onChange={e => setPassword(e.target.value)} />

                                                        <Button variant="link" className="btn shadow-none ms-0" onClick={togglePasswordVisibility} id="password-addon">
                                                            <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                                                        </Button>

                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row mb-29">
                                                    <div className="col">
                                                        <Form.Check label="Remember me" type="checkbox" id="remember-check" />
                                                    </div>

                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Button variant="primary" className="bg-primary w-100 waves-effect waves-light fs-18 font-w500" type="submit">Sign in</Button>
                                                </Form.Group>
                                                {errorMessage ? (
                                                    <div className="mt-3">
                                                        <p className="text-red">{errorMessage}</p>
                                                    </div>
                                                ) : null}
                                            </Form>

                                            <div className="mt-20 pt-2 text-center">
                                                <div className="signin-other-title">
                                                    <Link to="/" className="text-muted fs-14">Forgot password?</Link>
                                                </div>

                                            </div>

                                            <div className="mt-37 text-center">
                                                <p className="text-muted mb-0 fs-14">Don't have an account ? <Link to="/new-account" className="text-primary fw-semibold">  Create Account </Link></p>
                                            </div>

                                            <div className="mt-37 text-center">

                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <a href="javascript:void()" className="social-list-item text-white">
                                                            <i className='bx bxl-facebook-square'></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript:void()" className="social-list-item text-white">
                                                            <i className='bx bxl-twitter'></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript:void()" className="social-list-item  text-white">
                                                            <i className='bx bxl-linkedin-square'></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript:void()" className="social-list-item  text-white">
                                                            <i className='bx bxl-google-plus'></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Col>
            </Row>
        </Container>
    );

}

export default Login;