
import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import CreateProject from "./CreateProject";
import Avatar from "../images/avt-1.png";

function NotificationBox() {
    const [showCreateProjectPopup, setShowCreateProjectPopup] = useState(false);

    const handleCreateProject = (event) => {
        setShowCreateProjectPopup(true);
    }

    const closeCreateProjectPopup = (event) => {
        setShowCreateProjectPopup(false);
    }
    
    return(
        <Row>
            <Col xs={12}>
                <div className="box card-box">
                    <div className="icon-box bg-color-3">
                        <Link className="create d-flex" to="/calendar">
                            <div className="icon bg-icon-3">
                                <i className="bx bx-calendar"></i>
                            </div>
                            <div className="content">
                                <h5 className="title-box">Calendar</h5>
                                <p className="color-3 mb-0 pt-4">5 Unread notification</p>
                            </div>
                        </Link>
                    </div>
                    <div className="icon-box bg-color-4" onClick={handleCreateProject}>
                        <div className="create d-flex">
                            <div className="icon bg-white">
                                <i className="bx bx-plus"></i>
                            </div>
                            <div className="content d-flex align-items-center">
                                <h5 className="color-white">Create new project</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            {showCreateProjectPopup &&
                <Modal 
                    show={showCreateProjectPopup}
                    onHide={closeCreateProjectPopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal">
                    <Modal.Header>
                        <Modal.Title>Create Project</Modal.Title>
                        <Button className="close" onClick={closeCreateProjectPopup}><span aria-hidden="true">&times;</span></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateProject isPopup={true} />
                    </Modal.Body>
                </Modal>
            }
        </Row>
    );
}

export default NotificationBox;