import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '../components/Card';
import Header from '../components/Header';
import DataProjectServices from '../api/projects-api';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';

function Board() {
    let params = useParams();
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [boards, setBoards] = useState({});
    const { authTokens, } = useAuth();


    
    useEffect(() => {
        const fetchBoardDetails = async () => {
            setIsLoading(true);
            try {
                const data = await DataProjectServices.getBoardById(authTokens, params.id);
                console.log(data)
                setBoards(data)
            } catch (error) {
                console.error('Error fetching boards:', error);
            }finally {
                setIsLoading(false);
            }
        };

        fetchBoardDetails();
    }, [params.id, authTokens]);
     

    if (isLoading ) return <div>Loading...</div>;
    const boardId = params.id;
    return (
        <div>
            <Header />
            <div className="main">
                <div className="main-content board">

                    <div className="row">
                        <div className="col-12">
                            <div className="box">
                                <div className="box-header">

                                    {/* <h1>Project: {boards.project ? boards.project.name : 'Project Name Not Found'}</h1>
                                    <h2>Boards:</h2> */}
                                    <h4 className="box-title">{boards.project ? boards.project.name : 'Project Name Not Found'}</h4>

                                    <div className="box-right d-flex">
                                        <Link to={`/project/${params.id}`} className="btn">
                                            <span>Project Details</span>
                                        </Link>
                                        <div className="dropdown ml-14">
                                            <a href="#" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className='bx bx-dots-vertical-rounded fs-22'></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_project"><i className="bx bx-trash"></i> Delete</a>
                                                <a className="dropdown-item" href="#"><i className="bx bx-edit mr-5"></i>Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="d-none box-body d-flex justify-content-between pb-0">
                                    <div className="team-name">
                                        <a href="#" className="team">
                                            <div className="icon"><i className="fas fa-tags"></i></div>
                                            <h5>Team Name</h5>
                                        </a>
                                    </div>
                                    <div className="action">
                                        <a href="#" className="comment">32 Comments</a>
                                        <a href="#" className="edit">Edit</a>
                                        <a href="#" className="invite"><i className="fas fa-user-plus mr-5"></i>Invite People</a>
                                        <a href="#" className="add">New<i className="fas fa-caret-down pl-12"></i></a>
                                    </div>
                                    <ul className="user-list s2">
                                        {/* <li><img src="./images/avatar/team-1.png" alt="user"></li>
                                <li><img src="./images/avatar/team-2.png" alt="user"></li>
                                <li><img src="./images/avatar/team-3.png" alt="user"></li>
                                <li><img src="./images/avatar/team-4.png" alt="user"></li>
                                <li><img src="./images/avatar/team-5.png" alt="user"></li> */}
                                        <li className="total"><span>+4</span></li>
                                    </ul>
                                </div>

                                <Card boards={boards} />
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
}

export default Board;