import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import DataServices from '../api/services';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';


function AddClient() {
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    let params = useParams();
    const [formData, setFormData] = useState({
        workspace: '',
        user: null,
        type: null,
        name: null,

    });
    const [workspaces, setWorkspaces] = useState([]);
    const [userAccounts, setUsers] = useState([]);
    // const [type, setType] = useState([])
    const { authTokens, user } = useAuth();

    useEffect(() => {
        const fetchWorkspaces = async () => {
            try {
                const data = await DataServices.getWorkspaces(authTokens);

                if (Array.isArray(data.results)) {
                    setWorkspaces(data.results);
                } else {
                    console.error('Expected an array of workspaces, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching workspaces:', error);
            }
        };

        fetchWorkspaces();
    }, []);
    useEffect(() => {
        const fetchWorkspaces = async () => {
            try {
                const data = await DataServices.getUsers(authTokens);

                if (Array.isArray(data.results)) {
                    console.log(data.results)
                    let getAllUsers = [];
                    data.results.map((col) => {
                        if(col.user_details && col.first_name != '' && col.last_name != '') { //don't show users with no user_details and name
                            getAllUsers.push({'value': col.user_details.id, 'label': col.username});
                        }
                    });
                    console.log(getAllUsers)
                    setUsers(getAllUsers);
                } else {
                    console.error('Expected an array of workspaces, received:', data.results);
                }
            } catch (error) {
                console.error('Error fetching workspaces:', error);
            }
        };

        fetchWorkspaces();
    }, []);
    
    const handleChange = (e) => {
        const { name, value, type, options } = e.target;
    
        if (type === "radio") {
            // Handle radio buttons
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (e.target.multiple) {
            // Handle multiple selects for collaborators
            const selectedValues = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
            setFormData(prevState => ({
                ...prevState,
                [name]: selectedValues
            }));
        } else {
            // Handle other input types here (e.g., text, select single option)
            setFormData(prevState => ({
                ...prevState,
                [name]: type === 'number' ? Number(value) : value
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let submitData = { ...formData };

        const data = new FormData();
        Object.keys(submitData).forEach(key => {
            data.append(key, submitData[key]);
        });

        try {

            const clientResponse = await DataServices.createClients(formData, authTokens);

            if (clientResponse.status === 200 || clientResponse.status === 201) {
                setIsSuccessPopupVisible(true);
            }
        } catch (error) {
            setIsErrorPopupVisible(true);
            console.error("An error occurred:", error);
        }
    };

    const closePopup = () => {
        setIsSuccessPopupVisible(false);
        setIsErrorPopupVisible(false);
    };

    return (
        <div>
            <Header />
            <div className="main">
                <div className="main-content project">
                    <Row>
                        <Col md={12}>
                            <div className="box">
                                <div className="box-body">
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            
                                            <Col md={6} sm={12} className="mb-24">
                                                <Form.Group>
                                                    <Form.Label>User account:</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="user"
                                                        value={formData.user} // Changed from formData.client.id to formData.client
                                                        onChange={handleChange}
                                                        required>
                                                        <option value="">Select user account</option>
                                                        {userAccounts.map(userAccounts => (
                                                            <option key={userAccounts.value} value={userAccounts.value}>
                                                                {userAccounts.label}
                                                            </option>
                                                        ))}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12} className="mb-24">
                                                <Form.Group>
                                                    <Form.Label>Type:</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="type"
                                                        value={formData.type} // Changed from formData.client.id to formData.client
                                                        onChange={handleChange}
                                                        required>
                                                        <option value="">Select client type</option>
                                                        
                                                        <option value="lead">Lead</option>
                                                        <option value="proposal sent">Proposal Sent</option>
                                                        <option value="contract signed">Contract Signed</option>
                                                        <option value="archived">Archived</option>
                                                        <option value="completed">Completed</option>
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12} className="mb-24">
                                                <Form.Group>
                                                    <Form.Label>Workspace:</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="workspace"
                                                        value={formData.workspace}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Workspace</option>
                                                        {workspaces.map(workspace => (
                                                            <option key={workspace.id} value={workspace.id}>
                                                                {workspace.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className="gr-btn mt-15">
                                            <Button variant="primary" type="submit" className="btn-lg fs-16">SUBMIT</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
            {isSuccessPopupVisible && (
                 <Modal 
                    show={isSuccessPopupVisible}
                    onHide={closePopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal">
                    <Modal.Header>
                        <Button className="close" onClick={closePopup}><span aria-hidden="true">&times;</span></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Post successful!</p>
                    </Modal.Body>
                </Modal>
            )}
            {isErrorPopupVisible && (
                 <Modal 
                    show={setIsErrorPopupVisible}
                    onHide={closePopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal">
                    <Modal.Header>
                        <Button className="close" onClick={closePopup}><span aria-hidden="true">&times;</span></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>There are errors on the form data. Check browser console for more information.</p>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    )
}

export default AddClient;